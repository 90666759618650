import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { MainComponent } from '../../common/pages/main/main.component';
import { Residence } from '../../services/residence/residence.model';
import { SettingService } from '../../common/services/setting/setting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResidenceService } from '../../services/residence/residence.service';
import { Unit } from '../../services/unit/unit.model';
import { SelectService } from '../../common/services/select/select.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';
import { TranslationService } from 'src/common/services/translation/translation.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnitService } from 'src/services/unit/unit.service';
import { SwapAgentComponent } from 'src/components/actions-dialog/swap-agent/swap-agent.component';
import { ActionService } from 'src/services/action/action.service';
import { Action } from 'src/services/action/action.model';

@Component({
  selector: 'app-residence',
  templateUrl: './residence.component.html',
  styleUrls: ['./residence.component.scss']
})
export class ResidenceComponent extends MainComponent {

  public item: Residence;
  // To Use in Units Table
  public unitModel = new Unit();
  public vefas = null;
  public units = null;
  // public newAction = { check: false };
  public actionButtons: { id: string, icon: string, color?: string, label?: string }[] = [];

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: ResidenceService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public backURL: BackurlService,
    public translation: TranslationService,
    public dialog: MatDialog,
    public actionService: ActionService,
  ) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Residence(null, setting);
  }

  _itemOnInit() {
    this.imageConf = { path: this.item.imageConfig.path, filename: this.item.imageConfig.filename(this.item.values) };

    this.actionService.setServerFilter(
      {
        filters: {
          'residence.$key': this.item.values.$key
        },
        sort: { active: 'date', direction: 'desc' },
      }
    );
  }

  protected _afterBindUpdates() {
    this._manageMenu()
    this._sortUnitVEFA();
  }

  protected _manageMenu() {
    this.actionButtons = [
      { id: 'sms', icon: this.GLOBAL.ICONS.NOTE, color: 'link', label: 'Add Note' },
      { id: 'call', icon: 'phone', color: 'primary', label: 'Call' },
      { id: 'NACall', icon: 'phone_disabled', color: 'primary', label: 'Not Answering' },
      { id: 'email', icon: 'email', color: 'primary', label: 'Message' },
    ];
  }

  private _sortUnitVEFA() {
    // Sort Units by Unit Number
    if (this.item.values.synaps && this.item.values.synaps.residenceUnitsArray) {
      this.units = this.item.values.synaps.residenceUnitsArray;
      const vefaTmp = [];
      const unitsTmp = [];
      for (const unit of this.item.values.synaps.residenceUnitsArray) {
        if (unit.vefa) {
          vefaTmp.push(unit);
        } else {
          unitsTmp.push(unit);
        }
      }
      this.vefas = vefaTmp.sort((a: any, b: any) => {
        if (!a.vefaName) return -1;
        if (!b.vefaName) return 1;
        return a.vefaName.localeCompare(b.vefaName);
      });
      this.units = unitsTmp.sort((a: any, b: any) => {
        if (!a.unitNb) return -1;
        if (!b.unitNb) return 1;
        return a.unitNb.localeCompare(b.unitNb);
      });

    }
  }

  translate() {
    if (this.item.values.intro) {
      this.translation.translate(
        'residences',
        'intro',
        this.item.values.$key,
        this.item.values.intro,
      );
    }
    if (this.item.values.snapshot) {
      for (const id in this.item.values.snapshot) {
        this.translation.translate(
          'residences',
          'snapshot',
          this.item.values.$key,
          this.item.values.snapshot[id].text,
          id,
          'text',
        );

      }
    }
    if (this.item.values.descs) {
      for (const id in this.item.values.descs) {
        this.translation.translate(
          'residences',
          'descs',
          this.item.values.$key,
          this.item.values.descs[id].title,
          id,
          'title',
        );
        this.translation.translate(
          'residences',
          'descs',
          this.item.values.$key,
          this.item.values.descs[id].text,
          id,
          'text',
        );

      }
    }
  }

  addUnit() {
    const data = { ok: false, residence: this.item };
    this.dialog.open(DialogAddUnitComponent, {
      width: '400px',
      disableClose: true,
      data: data
    });

  }

  createActionMenu(actionType: string) {

    // if (actionType === 'offer'
    //   || actionType === 'visit'
    //   || actionType === 'signAgree'
    //   || actionType === 'signDeed'
    //   || actionType === 'signLease') {
    //   this.newAction.check = true;
    // }

    this.actionService.createAction(Action, actionType,
      [{ item: this.item }],
      {
        realtor: this.item.values.realtor,
        agency: this.item.values.agency,
        done: actionType === 'NACall' ? true : false,
        syncList: true
      }
    );
  }

  handover() {
    const data: any = { realtor: [this.item.values.realtor], agency: this.item.values.agency };
    const dialogRef = this.dialog.open(SwapAgentComponent, {
      width: '450px',
      disableClose: true,
      data: data
    });


    dialogRef.afterClosed().subscribe(async () => {
      if ((data.realtor && data.realtor[0] && data.realtor[0] !== this.item.values.realtor) || (data.agency && data.agency !== this.item.values.agency)) {
        this.startSaving();
        this.item.values.actionUpdated = new Date();
        this.item.modify.actionUpdated = true;
        this.item.values.realtor = data.realtor[0];
        this.item.modify.realtor = true;
        this.item.values.agency = data.agency;
        this.item.modify.agency = true;
        this.saveItem();
      }
    });

  }

}


@Component({
  selector: 'app-dialog-add-unit',
  templateUrl: './add-unit.component.html',
})
export class DialogAddUnitComponent extends MainComponent {

  constructor(
    protected _cd: ChangeDetectorRef,
    public setting: SettingService,
    public db: UnitService,
    public select: SelectService,
    protected route: ActivatedRoute,
    protected router: Router,
    public dialogRef: MatDialogRef<any>,
    public backURL: BackurlService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(_cd, setting, db, select, route, router, backURL, dialog);
    this.item = new Unit(null, setting);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {

    this.item.values.synaps = {
      residenceUnits: {
        [this.data.residence.values.$key]: this.data.residence.alias
      }
    }
    this.db.saveNewItem(this.item).then((newKey) => {
      this.dialogRef.close();
      this.backURL.addURL(`/residences/${this.data.residence.values.$key}`);
      this.router.navigate([`/units/${newKey}`]);
    });
  }

}