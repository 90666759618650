
export class GlobalConstants {

    public LANGS = ['en', 'fr', 'de', 'ru'];

    public ICONS = {
        ADDRESS: 'place',
        AC: 'ac_unit',
        ACTION: 'assignment_turned_in',
        ACTIONS: 'checklist_rtl',
        ACTIVE: 'play_arrow',
        ALERT: 'error',
        AVAILABLE: 'meeting_room',
        BATH: 'bathtub',
        BED: 'single_bed',
        BIRTH: 'cake',
        BLOG: 'rss_feed',
        CANCELLED: 'cancel',
        CALL: 'phone',
        COMPANY: 'work',
        CONSTRUCTION: 'construction',
        CONTACT: 'person',
        CONTACTS: 'people_alt',
        CONTRACT: 'description',
        COPY: 'content_copy',
        DASHBOARD: 'dashboard',
        DATE: 'event',
        DEAL: 'task_alt',
        DELIVERED: 'real_estate_agent',
        DEPOSIT: 'account_balance',
        DESC: 'notes',
        DETAILS: 'list',
        DISCOUNT: 'percent',
        DISCOVERY: 'emoji_objects',
        DOLLAR: 'attach_money',
        DOWNLOAD: 'file_download',
        DURATION: 'schedule',
        DUPLICATE: 'content_copy',
        FAILED: 'cancel',
        FLAG: 'emoji_flags',
        EDIT: 'edit',
        EMAIL: 'email',
        ERROR: 'warning',
        EXCLUSIVE: 'thumb_up',
        GARAGE: 'garage',
        GARDEN: 'local_florist',
        HANDOVER: 'swap_horiz',
        HIDEPRICE: 'money_off',
        INFO: 'sync_problem',
        KEY: 'key',
        LAND: 'crop_free',
        LANG: 'record_voice_over',
        LINK: 'link',
        LIST: 'list',
        LOGS: 'archive',
        MANDATE: 'book',
        MANDATEADD: 'post_add',
        MAP: 'location_on',
        MARKETING: 'cloud',
        MARKETINGCHECK: 'cloud_done',
        MATCHING: 'sync_all',
        MEDIAS: 'subscriptions',
        NEW: 'fiber_new',
        NEWMANDATE: 'real_estate_agent',
        NEWREQUEST: 'exit_to_app',
        NOACTION: "rule",
        NONE: 'highlight_off',
        NOTE: 'edit_note',
        OFFER: 'attach_money',
        OFFLINE: 'cloud_off',
        ONLINE: 'cloud',
        ONLINEDONE: 'cloud_done',
        PARAM: 'tune',
        PAUSE: 'pause',
        PAYMENT: 'payments',
        PENDING: 'refresh',
        PERCENT: 'percent',
        PHONE: 'phone',
        PHOTOS: 'photo_library',
        PM: 'construction',
        POOL: 'pool',
        PRICER: 'local_offer',
        PROCESS: 'book',
        PROJECT: 'search',
        PROJECTADD: 'zoom_in',
        R1: 'emoji_objects',
        R2: 'request_quote',
        REFERRAL: 'emoji_people',
        RESIDENCE: 'domain',
        SECTION: 'segment',
        SETTING: 'settings',
        SIGN: 'border_color',
        SIGNAGREE: 'drive_file_rename_outline',
        SIGNDEED: 'border_color',
        SIGNLEASE: 'border_color',
        SONCAS: 'mood',
        SUMMARY: 'pending_actions',
        STAT: 'query_stats',
        SURFACE: 'architecture',
        TEMPERATURE: 'thermostat',
        THUMBUP: 'thumb_up',
        THUMBDOWN: 'thumb_down',
        TOPFLOOR: 'vertical_align_top',
        TOPIC: 'topic',
        TOTAL: 'functions',
        UNIT: 'home',
        UNITS: 'home_work',
        UNITADD: 'add_home',
        VEFA: 'map',
        VERSION: 'code',
        VIDEO: 'videocam',
        VIEW: 'visibility',
        VISIT: 'directions_walk',
        VISITREPORT: 'transfer_within_a_station',
        VR: '360',
        WC: 'wc',
        WORK: 'work',
    }
}