import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Residence } from './residence.model';

@Injectable({
  providedIn: 'root'
})
export class ResidenceService extends MainService {

  public refMode = true;
  public summary: { [id: string]: number } = {};

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
  ) {
    super(auth, afs, 'residence', 'residences', Residence);

    this.itemsPageConfig.mapParams = {
      'bounds': null,
      'lat': -20.2674,
      'long': 57.5766,
      'zoom': 10,
    }

    this._filters = {
      search: '',
      server: {
      },
      local: {
        localSearch: '',
        sort: { active: 'followUp', direction: 'asc' },
      }
    };

  }

  protected _refreshSummary() {
    this.summary = { total: this.items.length, activeVefa: 0, active: 0, none: 0 };
    for (const item of this.items) {
      if (item.vefa) {
        this.summary.activeVefa++;
      }
      if (item.active) {
        this.summary.active++;
      }
      if (item.status) {
        this.summary[item.status] ? this.summary[item.status]++ : this.summary[item.status] = 1;
      } else {
        this.summary.none++;
      }
    }
  }

  protected _afterRefreshList() {
    this._refreshSummary();
  }

}