import { Component } from '@angular/core';
import { MainsComponent } from '../../common/pages/mains/mains.component';
import { SettingService } from '../../common/services/setting/setting.service';
import { ResidenceService } from '../../services/residence/residence.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { SelectService } from '../../common/services/select/select.service';
import { BackurlService } from 'src/common/services/backurl/backurl.service';

@Component({
  selector: 'app-residences',
  templateUrl: './residences.component.html',
  styleUrls: ['./residences.component.scss']
})
export class ResidencesComponent extends MainsComponent {

  public preview: any = null;
  public paramInit: any = {};

  constructor(
    public setting: SettingService,
    public db: ResidenceService,
    public select: SelectService,
    protected router: Router,
    protected fb: FormBuilder,
    public backURL: BackurlService,
  ) {
    super(setting, db, select, router, fb, backURL);
    this.sortLocalForm = this.fb.group({
      localSearch: [''],
    });
    Object.assign(this.paramInit, this.db.itemsPageConfig.mapParams);


    this.sortLocalForm = this.fb.group({
      localSearch: [''],
      filters: this.fb.group({
        agency: ['N'],
        realtor: ['N'],
        status: ['N'],
        activeVefa: ['N'],
        active: ['N'],
      }),
      multiFilters: this.fb.group({
        'sector': [],
        'scheme': [],
        'status': [],
      }),
    });
  }



  goItem(item: any) {
    // Check if on preview first
    if (this.db.itemsPageConfig.sideTab === 1 && !this.select.active) {
      this.backURL.addURL(`/${this.db.moduleListName}/`);
      this.router.navigate([`/${this.db.moduleListName}/${item.$key}`]);
    } else {
      this.db.itemsPageConfig.sideTab = 1;
    }
  }

  toggleMap() {
    this.db.itemsPageConfig.map = !this.db.itemsPageConfig.map;
  }

  previewItem(item: any) {
    if (!(this.preview && item && item.$key === this.preview.$key)) {
      this.preview = null;
      setTimeout(() => {
        this.preview = item;
      }, 50);
    }
  }



  filterStatus(status: string) {
    const localFiltersStatus = this.sortLocalForm.get('filters.status');
    const localFiltersVefa = this.sortLocalForm.get('filters.activeVefa');
    const localFiltersActive = this.sortLocalForm.get('filters.active');

    if (status === 'none') {
      localFiltersStatus.setValue(null);
      localFiltersVefa.setValue('N');
      localFiltersActive.setValue('N');

    } else if (status === 'N') {
      localFiltersStatus.setValue('N');
      localFiltersVefa.setValue('N');
      localFiltersActive.setValue('N');
    } else if (status === 'active') {
      localFiltersStatus.setValue('N');
      localFiltersVefa.setValue('N');
      localFiltersActive.setValue(true);
    } else if (status === 'activeVefa') {
      localFiltersStatus.setValue('N');
      localFiltersVefa.setValue(true);
      localFiltersActive.setValue('N');
    } else {
      localFiltersStatus.setValue(status);
      localFiltersVefa.setValue('N');
      localFiltersActive.setValue('N');
    }
  }
}
