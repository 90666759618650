import { Injectable } from '@angular/core';
import { MainService } from '../../common/services/main/main.service';
import { AuthService } from '../../common/services/auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Mandate } from './mandate.model';
import { SettingService } from '../../common/services/setting/setting.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class MandateService extends MainService {
  public summary: { [id: string]: number } = {};
  public status: string[] = ['new', 'active', 'pending', 'offer', 'signAgree', 'signDeed', 'signLease', 'payment', 'pause']

  public refMode = true;

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public setting: SettingService,
  ) {
    super(auth, afs, 'mandate', 'mandates', Mandate);

    this._filters = {
      search: '',
      server: {
        filters: { referral: null, referralAgency: null },
        multiFilters: { status: ['new', 'active', 'pending', 'offer', 'signAgree', 'signDeed', 'signLease', 'payment', 'pause'] },
        realtor: this.selectRealtorFilter(),
        agency: this.selectAgencyFilter(),
      },
      local: {
        localSearch: '',
        filters: {
          type: 'N',
          condition: 'N',
          status: 'N',
          temperature: 'N',
          vefa: 'N',
        },
        sort: { active: 'followUp', direction: 'asc' },
      }
    };

  }

  protected _refreshSummary() {
    this.summary = {};
    for (const item of this.items) {
      if (this.status.includes(item.status)) {
        this.summary[item.status] ? this.summary[item.status]++ : this.summary[item.status] = 1;
        if (item.status === 'active' && item.temperature) {
          if (item.vefa) {
            this.summary.vefa ? this.summary.vefa++ : this.summary.vefa = 1;
          } else {
            this.summary[item.temperature] ? this.summary[item.temperature]++ : this.summary[item.temperature] = 1;
          }
        }
      }
    }
  }


  protected _afterRefreshList() {
    this._refreshSummary();
  }

  protected _afterLocalFilter() {
    // this._refreshSummary();
  }

  protected _localCustomOrder(direction: number): boolean {
    if (this._filters.local.sort.active === 'icon') {
      const statusPriority = {
        new: 1,
        estimation: 2,
        pending: 3,
        offer: 4,
        signAgree: 5,
        signDeed: 6,
        signLease: 6,
        payment: 7,
        active: 8, //8 et 10
        pause: 11,
        deal: 12,
        failed: 13,
      }

      const temperature = {
        hot: 0,
        warm: 1,
        cold: 2,
      }

      this.itemsFilteredOrdered = [...this.itemsFiltered.sort((a, b) => {

        const scoreA = a.status === 'active' ? statusPriority[a.status] + temperature[a.temperature ? a.temperature : 'cold'] : statusPriority[a.status];
        const scoreB = b.status === 'active' ? statusPriority[b.status] + temperature[b.temperature ? b.temperature : 'cold'] : statusPriority[b.status];

        if (scoreA !== scoreB) {
          return (scoreA < scoreB ? -1 : 1) * direction;
        } else if (a.dateNeed > b.dateNeed) {
          return -1 * direction;
        } else {
          return 1 * direction;
        }
      }
      )];
      return true;
    }

    return false;
  }

  forceMandateSync(item: Mandate, portal: string | null = null) {

    if (this.canEdit) {
      const data: any = {
        mandateKey: item.values.$key,
        updated: firebase.firestore.FieldValue.serverTimestamp(),
        mandate: true,
        unpublish: item.values.status !== 'active' || !item.values.ad,
      };

      if (portal) {
        data.portal = portal;
      }

      return this.afs.doc(`syncMandates/${item.values.$key}`).set(data);
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class MyMandateService extends MandateService {


  // private _status: string[] = ['new', 'active', 'pending', 'offer', 'signAgree', 'signDeed', 'signLease', 'payment']
  // public summary: { [id: string]: number } = {};

  constructor(
    public auth: AuthService,
    public afs: AngularFirestore,
    public setting: SettingService,
  ) {
    super(auth, afs, setting);

    this._filters = {
      search: '',
      server: {
        multiFilters: { status: [...this.status] },
        realtor: this.auth.uid,
      },
      local: {
        localSearch: '',
        sort: { active: 'icon', direction: 'asc' },
      }
    };
  }

  // protected _afterRefreshList() {
  //   this.summary = {};
  //   for (const item of this.items) {
  //     if (this._status.includes(item.status)) {
  //       this.summary[item.status] ? this.summary[item.status]++ : this.summary[item.status] = 1;
  //       if (item.status === 'active' && item.temperature) {
  //         this.summary[item.temperature] ? this.summary[item.temperature]++ : this.summary[item.temperature] = 1;
  //       }
  //     }
  //   }
  // }
}