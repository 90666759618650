// import { LatLngLiteral } from "@agm/core";
import { GlobalConstants } from "./global-constants";

export interface Param {
    $key: string,
    title: string;
    name: string;
    multiLang: boolean;
    desc: boolean;
    db: boolean;
    editable: boolean;
    values: { [key: string]: { name: string | { [lang: string]: string }, icon?: string, color?: string, info?: any } }
}


interface sectors { name: string, title: string, paths: /*LatLngLiteral*/ any[], color: string, sectors: sectors[] | null }

export class PreSettings {

    public GLOBAL = new GlobalConstants();

    private _params: { [key: string]: Param } =
        {

            // agencies: {
            //     $key: 'agencies',
            //     title: 'Agencies',
            //     name: 'Agency',
            //     multiLang: false,
            //     desc: false,
            //     db: false,
            //     editable: false,
            //     values: {
            //         cp: { name: 'Curepipe' },
            //         gb: { name: 'Grand Baie' },
            //         mk: { name: 'Moka' },
            //         pde: { name: 'Pointe d\'Esny' },
            //         tam: { name: 'Tamarin' },
            //     }
            // },

            alerts: {
                $key: 'alerts',
                title: 'Alerts',
                name: 'Alert',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    missingGPS: { name: 'Missing GPS' },
                    missingCity: { name: 'Missing City' },
                    missingUnitNb: { name: 'Missing Unit Number' },
                    missingLandType: { name: 'Missing Land Type' },
                    missingTitle: { name: 'Missing Titles' },
                    missingDesc: { name: 'Missing Descriptions' },
                    missingPhotos: { name: 'Missing Photos' },
                    missingBed: { name: 'Missing Bedroom Numbers' },
                    missingBath: { name: 'Missing Bathroom Numbers' },
                    missingSurface: { name: 'Missing Unit Surface' },
                    missingCondition: { name: 'Missing Unit Condition' },
                    missingLand: { name: 'Missing Land Surface' },
                    missingFloor: { name: 'Missing Floor Level' },
                    missingTopFloor: { name: 'Missing Top Floor Level' },
                    missingStoreys: { name: 'Missing Numer of Storeys' },
                    missingTotalUnits: { name: 'Missing Total Units' },
                    missingTypes: { name: 'Missing Unit Types' },
                    missingStatus: { name: 'Missing Status' },
                    missingYear: { name: 'Missing Delivery Year' },
                    short1000Desc: { name: 'Description: less than 1000 characters' },
                    short500Desc: { name: 'Description: less than 500 characters' },
                    less10Photos: { name: 'Photos: Less than 10' },
                    less15Photos: { name: 'Photos: Less than 15' },
                    less20Photos: { name: 'Photos: Less than 20' },
                    less35Photos: { name: 'Photos: Less than 35' },
                    missingVideo: { name: 'Video: Missing' },
                }
            },

            blogTopics: {
                $key: 'blogTopics',
                title: 'Blog Topics',
                name: 'Topic',
                multiLang: true,
                desc: false,
                db: false,
                editable: false,
                values: {
                    buysell: { info: { info: { online: true } }, name: { en: 'Buy & Sell', fr: 'Achat & Vente', de: 'Kaufen & Verkaufen', ru: 'Kuplia & Prodazha' } },
                    pm: { info: { online: true }, name: { en: 'Rental & Management', fr: 'Location & Gestion', de: 'Vermietung & Management', ru: 'Arenda & Upravlenie' } },
                    invest: { info: { online: true }, name: { en: 'Investment', fr: 'Investissement', de: 'Investition', ru: 'Investitsii' } },
                    lifestyle: { info: { online: true }, name: { en: 'Decoration & Lifestyle', fr: 'Décoration & Lifestyle', de: 'Dekoration & Lebensstil', ru: 'Dekor & Obraz zhizni' } },
                    legal: { info: { online: true }, name: { en: 'Legal & Regulatory', fr: 'Juridique & Réglementation', de: 'Rechtliche und regulatorische Fragen', ru: 'Yuridicheskie & Regulyatornye voprosy' } },
                    vefa: { info: { online: true }, name: { en: 'New Projects', fr: 'Projets Neufs', de: 'Neue Projekte', ru: 'Novye Proekty' } },
                    taxation: { info: { online: true }, name: { en: 'Financing & Taxation', fr: 'Financement & Fiscalité', de: 'Finanzierung und Besteuerung', ru: 'Finansirovanie & Nalogooblozhenie' } },
                    market: { info: { online: true }, name: { en: 'Real estate market', fr: 'Marché de l\'immobilier', de: 'Grundstücksmarkt', ru: 'Rynok nedvizhimosti' } },
                    nestenn: { info: { online: true }, name: { en: 'The Nestenn Franchise', fr: 'La Franchise Nestenn', de: 'Die Nestenn-Franchise', ru: 'Franshiza Nestenn' } },
                    covid: { info: { online: true }, name: { en: 'News COVID-19', fr: 'Actualités COVID-19', de: 'Nachrichten COVID-19', ru: 'Novosti COVID-19' } },
                    other: { info: { online: true }, name: { en: 'Other', fr: 'Autre', de: 'Andere', ru: 'Drugoe' } },
                }
            },

            blogSectionTypes: {
                $key: 'blogSectionTypes',
                title: 'Blog Sections Types',
                name: 'Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    text: { name: 'Text' },
                    img: { name: 'Image' },
                    imgText: { name: 'Text/Image' },
                }
            },

            blogImagePositions: {
                $key: 'blogImagePositions',
                title: 'Blog Image Positions',
                name: 'Position',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    right: { name: 'Right' },
                    left: { name: 'Left' },
                },
            },

            cies: {
                $key: 'cies',
                title: 'Currencies',
                name: 'Currency',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    mur: { name: 'Rs' },
                    eur: { name: '€' },
                    usd: { name: '$' },
                    zar: { name: 'R' },
                }
            },

            langs: {
                $key: 'langs',
                title: 'Languages',
                name: 'Language',
                multiLang: false,
                desc: false,
                db: true,
                editable: true,
                values: {}
            },

            fileTypes: {
                $key: 'fileTypes',
                title: 'File Types',
                name: 'File Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    "image/jpeg": { name: 'JPG', icon: 'image' },
                }
            },

            userRoles: {
                $key: 'userRoles',
                title: 'User Roles',
                name: 'Role',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    admin: { name: 'Administrator' },
                    assistant: { name: 'Assistant' },
                    director: { name: 'Director' },
                    realtor: { name: 'Agent' },
                    marketing: { name: 'Marketing' },
                    accountant: { name: 'Accountant' },
                    pm: { name: 'Property Management' },
                }
            },

            userSpecialities: {
                $key: 'userSpecialities',
                title: 'User Specialities',
                name: 'Speciality',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    saleForeigner: { name: 'Sale Foreigner' },
                    saleLocal: { name: 'Sale Local' },
                    rental: { name: 'Rental' },
                }
            },

            userStatus: {
                $key: 'userStatus',
                title: 'User Status',
                name: 'Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    active: { name: 'Active', icon: 'check_circle' },
                    blocked: { name: 'Cancelled', icon: 'highlight_off' },
                    pending: { name: 'Pending', icon: 'pending' }
                }
            },

            relationTypes: {
                $key: 'relationTypes',
                title: 'Relation Types',
                name: 'Relation Type',
                multiLang: false,
                desc: false,
                db: true,
                editable: true,
                values: {}
            },

            unitTypes: {
                $key: 'unitTypes',
                title: 'Unit Types',
                name: 'Unit Type',
                multiLang: true,
                desc: false,
                db: false,
                editable: false,
                values: {
                    apartment: { name: { en: 'Apartment', fr: 'Appartement', de: 'Wohnung', ru: 'Kvartira' }, icon: 'apartment' },
                    house: { name: { en: 'House-Villa', fr: 'Maison-Villa', de: 'Haus-Villa', ru: 'Dom-Villa' }, icon: 'home' },
                    office: { name: { en: 'Office', fr: 'Bureau', de: 'Büro', ru: 'Ofis' }, icon: 'work' },
                    commercial: { name: { en: 'Commercial', fr: 'Commerce', de: 'Handel', ru: 'Kommertcheskaya' }, icon: 'shopping_cart' },
                    land: { name: { en: 'Land', fr: 'Terrain', de: 'Grundstück', ru: 'Zemlya' }, icon: 'crop_free' },
                    building: { name: { en: 'Commercial Building', fr: 'Batiment Commercial', de: 'Gebäude', ru: 'Zdanie' }, icon: 'location_city' },
                    business: { name: { en: 'Business', fr: 'Fond de commerce', de: 'Geschäftsgrundlagen', ru: 'Biznes' }, icon: 'request_page' },
                    complex: { name: { en: 'Residential complex', fr: 'Complexe résidentiel', de: 'Wohnkomplex', ru: 'Zhiloy kompleks' }, icon: 'holiday_village' },
                    warehouse: { name: { en: 'Warehouse', fr: 'Entrepôt', de: 'Lagerhaus', ru: 'Sklad' }, icon: 'warehouse' },
                    hotel: { name: { en: 'Hotel', fr: 'Hotel', de: 'Hotel', ru: 'Otel' }, icon: 'beach_access' },
                }
            },

            unitTypeDisps: {
                $key: 'unitTypeDisps',
                title: 'Unit Display Types',
                name: 'Unit Display Type',
                multiLang: true,
                desc: false,
                db: false,
                editable: false,
                values: {
                    apartment: { name: { en: 'Apartment', fr: 'Appartement', de: 'Wohnung', ru: 'Kvartira' }, icon: 'home' },
                    house: { name: { en: 'House-Villa', fr: 'Maison-Villa', de: 'Haus-Villa', ru: 'Dom-Villa' }, icon: 'apartment' },
                    office: { name: { en: 'Office', fr: 'Bureau', de: 'Büro', ru: 'Ofis' }, icon: 'work' },
                    commercial: { name: { en: 'Commercial Premises', fr: 'Local Commercial', de: 'Handel', ru: 'Kommercheskaya' }, icon: 'shopping_cart' },
                    land: { name: { en: 'Land', fr: 'Terrain', de: 'Grundstück', ru: 'Zemlya' }, icon: 'crop_free' },
                    building: { name: { en: 'Commercial Building', fr: 'Batiment Commercial', de: 'Gebäude', ru: 'Zdaniye' }, icon: 'location_city' },
                    townhouse: { name: { en: 'Townhouse', fr: 'Maison Mitoyenne', de: 'Reihenhaus', ru: 'Taunkhaus' }, icon: 'apartment' },
                    penthouse: { name: { en: 'Penthouse', fr: 'Penthouse', de: 'Penthouse', ru: 'Pentkhaus' }, icon: 'apartment' },
                    business: { name: { en: 'Business', fr: 'Fond de commerce', de: 'Geschäftsgrundlagen', ru: 'Biznes' }, icon: 'request_page' },
                    complex: { name: { en: 'Residential complex', fr: 'Complexe résidentiel', de: 'Wohnkomplex', ru: 'Zhiloy kompleks' }, icon: 'holiday_village' },
                    warehouse: { name: { en: 'Warehouse', fr: 'Entrepôt', de: 'Lagerhaus', ru: 'Sklad' }, icon: 'warehouse' },
                }
            },

            unitStoreys: {
                $key: 'unitStoreys',
                title: 'Storey Types',
                name: 'Storey Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    s1: { name: 'Single-Storey' },
                    s2: { name: 'Duplex' },
                    s3: { name: 'Triplex' },
                    s4: { name: 'Fourplex' },
                    s5: { name: 'Manyplex' },
                }
            },

            unitFloors: {
                $key: 'unitFloors',
                title: 'Unit Floors',
                name: 'Unit Floor',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    l0: { name: 'Ground Floor' },
                    l1: { name: 'Level 1' },
                    l2: { name: 'Level 2' },
                    l3: { name: 'Level 3' },
                    l4: { name: 'Level 4' },
                    l5: { name: 'Level 5' },
                    l6: { name: 'Level 6' },
                    l7: { name: 'Level 7' },
                    l8: { name: 'Level 8' },
                    l9: { name: 'Level 9' },
                    l10: { name: 'Level 10' },
                }
            },

            unitConditions: {
                $key: 'unitConditions',
                title: 'Unit Conditions',
                name: 'Unit Condition',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    cons: { name: 'Under Construction' },
                    new: { name: 'New' },
                    good: { name: 'Good' },
                    renov: { name: 'Need Renovations' },
                    recentlyRefurbished: { name: 'Recently Refurbished' },
                }
            },

            unitConditionsVEFA: {
                $key: 'unitConditionsVEFA',
                title: 'Unit Conditions including VEFA',
                name: 'Unit Condition',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    vefa: { name: 'VEFA/Promoter' },
                    cons: { name: 'Under Construction' },
                    new: { name: 'New' },
                    good: { name: 'Good' },
                    renov: { name: 'Need Renovations' },
                    recentlyRefurbished: { name: 'Recently Refurbished' },
                }
            },

            residenceStatus: {
                $key: 'residenceStatus',
                title: 'Residence Status',
                name: 'Residence Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    new: { name: 'New', icon: this.GLOBAL.ICONS.NEW, color: 'hot' },
                    pndg: { name: 'Pending', icon: this.GLOBAL.ICONS.PENDING, color: 'warm' },
                    vefa: { name: 'Off Plan', icon: this.GLOBAL.ICONS.VEFA, color: 'vefa' },
                    cons: { name: 'Under Construction', icon: this.GLOBAL.ICONS.CONSTRUCTION, color: 'hot' },
                    deli: { name: 'Delivered', icon: this.GLOBAL.ICONS.DELIVERED, color: 'residences'  },
                    canc: { name: 'Cancelled', icon: this.GLOBAL.ICONS.CANCELLED },
                }
            },

            unitStyles: {
                $key: 'unitStyles',
                title: 'Unit Style',
                name: 'Unit Style',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    trad: { name: 'Traditional' },
                    contemp: { name: 'Contemporary' },
                    modern: { name: 'Modern' },
                    colonial: { name: 'Colonial' },
                    atypical: { name: 'Atypical' },
                }
            },

            expositions: {
                $key: 'expositions',
                title: 'Expositions',
                name: 'Exposition',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    north: { name: 'North' },
                    ne: { name: 'North-Est' },
                    nw: { name: 'North-West' },
                    south: { name: 'South' },
                    se: { name: 'South-Est' },
                    sw: { name: 'South-West' },
                    est: { name: 'Est' },
                    west: { name: 'West' },
                },
            },

            landTypes: {
                $key: 'landTypes',
                title: 'Land Types',
                name: 'Land Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    residential: { name: 'Residential' },
                    agricultural: { name: 'Agricultural' },
                    commercial: { name: 'Commercial' },
                },
            },

            landUnits: {
                $key: 'landUnits',
                title: 'Land Units',
                name: 'Land Unit',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    m2: { name: 'm²' },
                    arp: { name: 'Arpents' },
                    rd: { name: 'Perches' },
                },
            },

            projectTypes: {
                $key: 'projectTypes',
                title: 'Project Types',
                name: 'Project Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    rental: { name: 'Rental' },
                    sale: { name: 'Buy' },
                }
            },

            mandateTypes: {
                $key: 'mandateTypes',
                title: 'Mandate Types',
                name: 'Mandate Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    rental: { name: 'Rental' },
                    sale: { name: 'Sale' },
                }
            },

            temperatures: {
                $key: 'temperatures',
                title: 'Temperatures',
                name: 'Temperature',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    cold: { name: 'Cold', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    warm: { name: 'Warm', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    hot: { name: 'Hot', icon: this.GLOBAL.ICONS.TEMPERATURE },
                }
            },

            temperaturesVEFA: {
                $key: 'temperaturesVEFA',
                title: 'Temperatures',
                name: 'Temperature',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    cold: { name: 'Cold', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    warm: { name: 'Warm', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    hot: { name: 'Hot', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    vefa: { name: 'VEFA', icon: this.GLOBAL.ICONS.OFFLINE },
                }
            },

            projectStatus: {
                $key: 'projectStatus',
                title: 'Project Status',
                name: 'Project Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    new: { name: 'New', icon: 'fiber_new' },
                    discovery: { name: 'Discovery', icon: this.GLOBAL.ICONS.DISCOVERY },
                    offer: { name: 'Under Offer', icon: 'attach_money' },
                    signAgree: { name: 'Pending Sales Agreement', icon: 'drive_file_rename_outline' },
                    signDeed: { name: 'Pending Deed of Sale', icon: 'border_color' },
                    signLease: { name: 'Pending Lease Agreement', icon: 'border_color' },
                    payment: { name: 'Pending Payment', icon: 'payments' },
                    active: { name: 'Active', icon: 'search' },
                    pause: { name: 'Pause', icon: 'pause' },
                    deal: { name: 'Deal', icon: 'task_alt' },
                    failed: { name: 'Failed', icon: 'cancel' },
                }
            },

            mandateStatus: {
                $key: 'mandateStatus',
                title: 'Mandate Status',
                name: 'Mandate Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    new: { name: 'New', icon: 'fiber_new' },
                    pending: { name: 'In Process', icon: 'refresh' },
                    active: { name: 'Active', icon: 'book' },
                    offer: { name: 'Under Offer', icon: 'attach_money' },
                    signAgree: { name: 'Pending Sales Agreement', icon: 'drive_file_rename_outline' },
                    signDeed: { name: 'Pending Deed of Sale', icon: 'border_color' },
                    signLease: { name: 'Pending Lease Agreement', icon: 'border_color' },
                    payment: { name: 'Pending Payment', icon: 'payments' },
                    deal: { name: 'Deal', icon: 'task_alt' },
                    pause: { name: 'Pause', icon: 'pause' },
                    failed: { name: 'Failed', icon: 'cancel' },
                }
            },

            mandateFailedReasons: {
                $key: 'mandateFailedReasons',
                title: 'Mandate Failed Reasons',
                name: 'Mandate Failed Reason',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    otherAgency: { name: "Other Agency's Client" },
                    owner: { name: "Owner's Client" },
                    duplicate: { name: 'Duplicate' },
                    other: { name: 'Other Reason' },
                }
            },

            mandateOrigins: {
                $key: 'mandateOrigins',
                title: 'Mandate Origins',
                name: 'Mandate Origin',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    an: { name: "Agent's Network" },
                    ia: { name: "Inter-Agency" },
                    lpm: { name: "LP Magazine" },
                    web: { name: "Website" },
                    wni: { name: "Nestenn France Website" },
                    niia: { name: "Nestenn France" },
                    rep: { name: "Repeater" },
                    noto: { name: "Notoriety" },
                    fcb: { name: "Facebook" },
                    lki: { name: "LinkedIn" },
                    tik: { name: "Tik Tok" },
                    pros: { name: "Prospection" },
                    pm: { name: "Property Management" },
                    wi: { name: "Walk-in" },
                    vefa: { name: "VEFA/Promoter" },
                    other: { name: 'Other Origin' },
                }
            },

            projectFailedReasons: {
                $key: 'projectFailedReasons',
                title: 'Project Failed Reasons',
                name: 'Project Failed Reason',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    wrongContact: { name: "Wrong Contact Details" },
                    notAnswering: { name: "Not Answering" },
                    shortTerm: { name: "Short Term Location" },
                    otherAgency: { name: "Found with other agency" },
                    direct: { name: "Found by themself" },
                    cancel: { name: "Cancelled their Project" },
                    nonViable: { name: "Non-Viable Project" },
                    duplicate: { name: "Duplicate" },
                    other: { name: 'Other Reason' },
                }
            },

            newRequestFailedReasons: {
                $key: 'newRequestFailedReasons',
                title: 'New Request Failed Reasons',
                name: 'New Request Failed Reason',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    wrongContact: { name: "Wrong Contact Details" },
                    shortTerm: { name: "Short Term Location" },
                    spam: { name: "SPAM" },
                    duplicate: { name: "Duplicate" },
                    other: { name: 'Other Reason' },
                }
            },

            projectOrigins: {
                $key: 'projectOrigins',
                title: 'Project Origins',
                name: 'Project Origin',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    web: { name: "Website" },
                    lpw: { name: "LP Website" },
                    lpm: { name: "LP Magazine" },
                    lpsd: { name: "LP Specific Demand" },
                    pc: { name: "PropertyCloud" },
                    pmw: { name: "PropertyMap" },
                    jew: { name: "JamesEdition" },
                    mer: { name: "Mer & Demeures" },
                    ps: { name: "Properstar" },
                    visa: { name: "VISA Maurice" },
                    an: { name: "Agent's Network" },
                    en: { name: "Enews" },
                    gmb: { name: "Google My Business" },
                    exb: { name: "Expat Blog" },
                    fcb: { name: "Facebook" },
                    lki: { name: "LinkedIn" },
                    tik: { name: "Tik Tok" },
                    ia: { name: "Inter-Agency" },
                    lc: { name: "L'Express Classified" },
                    lnp: { name: "L'Express Newspaper" },
                    wni: { name: "Nestenn France Website" },
                    niia: { name: "Nestenn France" },
                    noto: { name: "Notoriety" },
                    pm: { name: "Property Management" },
                    pros: { name: "Prospection" },
                    rep: { name: "Repeater" },
                    wi: { name: "Walk-in" },
                    mich: { name: "Micheline" },
                    polo: { name: "Polo Properties" },
                    other: { name: 'Other Origin' },
                }
            },

            actualPlaceTypes: {
                $key: 'actualPlaceTypes',
                title: 'Actual Places',
                name: 'Actual Place',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    apartment: { name: 'Apartment' },
                    house: { name: 'House/Villa' },
                    office: { name: 'Office' },
                    commercial: { name: 'Commercial' },
                    hotel: { name: 'Hotel' },
                    abroad: { name: 'Abroad' },
                }
            },


            residenceSchemes: {
                $key: 'residenceSchemes',
                title: 'Residence Schemes',
                name: 'Residence Scheme',
                multiLang: true,
                desc: false,
                db: false,
                editable: false,
                values: {
                    irs: { name: { en: 'IRS', fr: 'IRS', de: 'IRS', ru: 'IRS' } },
                    res: { name: { en: 'RES', fr: 'RES', de: 'RES', ru: 'RES' } },
                    ihs: { name: { en: 'IHS', fr: 'IHS', de: 'IHS', ru: 'IHS' } },
                    pds: { name: { en: 'PDS', fr: 'PDS', de: 'PDS', ru: 'PDS' } },
                    senior: { name: { en: 'Senior Residence', fr: 'Residence Seniors', de: 'Seniorenresidenz', ru: 'Rezidentsiya dlya pozhilykh' } },
                    smart: { name: { en: 'Smart City', fr: 'Smart City', de: 'Smart City', ru: 'Umnyy gorod' } },
                }
            },

            agencyFeeTypes: {
                $key: 'agencyFeeTypes',
                title: 'Agency Fee Types',
                name: 'Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    std: { name: 'Standard + VAT' },
                    otherPerc: { name: 'Other Percentage + VAT' },
                    discountVAT: { name: 'Discount: includes VAT' },
                    discount50: { name: 'Discount: 50%' },
                    fix: { name: 'Fix Amount + VAT' },
                    no: { name: 'No Agency Fee' },
                }
            },

            actionModels: {
                $key: 'actionModels',
                title: 'Action Models',
                name: 'Action Model',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    project: { name: 'Client' },
                    mandate: { name: 'Mandate' },
                }
            },

            actionTypes: {
                $key: 'actionTypes',
                title: 'Action Types',
                name: 'Action Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {

                    // Mandate
                    visitReport: { name: 'Visit Report', icon: this.GLOBAL.ICONS.VISITREPORT, info: { module: 'mandates', order: 5 } },
                    r1: { name: 'R1 - Discovery', icon: this.GLOBAL.ICONS.R1, info: { module: 'mandates', order: 3 } },
                    r2: { name: 'R2 - Estimation', icon: this.GLOBAL.ICONS.R2, info: { module: 'mandates', order: 4 } },
                    signMandate: { name: 'sign Mandate', icon: this.GLOBAL.ICONS.NEWMANDATE, info: { module: 'mandates', order: 2 } },
                    newMandate: { name: 'New Mandate', icon: 'open_in_new', info: { module: 'mandates', order: 1 } },
                    advertise: { name: 'Advertise', icon: this.GLOBAL.ICONS.MARKETING, info: { module: 'mandates', order: 6 } },
                    marketingCheck: { name: 'Marketing Check', icon: this.GLOBAL.ICONS.MARKETINGCHECK, info: { module: 'marketing', order: 2 } },


                    // Project
                    newRequest: { name: 'New Request', icon: 'exit_to_app', info: { module: 'projects', order: 1 } },
                    discovery: { name: 'Discovery', icon: this.GLOBAL.ICONS.DISCOVERY, info: { module: 'projects', order: 2 } },
                    visit: { name: 'Visit', icon: 'directions_walk', info: { module: 'projects', order: 3 } },
                    offer: { name: 'Offer', icon: 'attach_money', info: { module: 'projects', order: 4 } },
                    signAgree: { name: 'Sales Agreement', icon: 'drive_file_rename_outline', info: { module: 'projects', order: 5 } },
                    signDeed: { name: 'Deed of Sale', icon: 'border_color', info: { module: 'projects', order: 6 } },
                    signLease: { name: 'Lease Agreement', icon: 'border_color', info: { module: 'projects', order: 5 } },

                    // All
                    meeting: { name: 'Meeting', icon: 'group', info: {} },
                    payment: { name: 'Payment', icon: 'payments', info: {} },
                    call: { name: 'Call', icon: 'phone', info: {} },
                    email: { name: 'Email', icon: 'email', info: {} },
                    sms: { name: 'Note', icon: this.GLOBAL.ICONS.NOTE, info: {} },

                    // Action Done Directly -> Log only
                    handover: { name: 'Handover', icon: 'swap_horiz' },
                    temperature: { name: 'Temperature', icon: this.GLOBAL.ICONS.TEMPERATURE },
                    active: { name: 'Project Active', icon: 'play_arrow' },
                    accept: { name: 'Project Accept', icon: 'thumb_up' },
                    pause: { name: 'Project Pause', icon: 'pause' },
                    NACall: { name: 'Not Answering Call', icon: 'phone_disabled' },
                    price: { name: 'Price Change', icon: this.GLOBAL.ICONS.PRICER, info: { module: 'mandates' } },
                    failed: { name: 'Failed', icon: 'cancel' },

                    // For Display Only
                    projects: { name: 'Project Actions', icon: this.GLOBAL.ICONS.PROJECT, info: { module: 'projects', order: 0 } },
                    mandates: { name: 'Mandate Actions', icon: this.GLOBAL.ICONS.MANDATE, info: { module: 'mandates', order: 0 } },
                }
            },

            actionTypeFilters: {
                $key: 'actionTypeFilters',
                title: 'Action Type Filter',
                name: 'Action Type Filter',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    newRequest: { name: 'New Request', icon: 'exit_to_app', info: { module: 'projects' } },
                    discovery: { name: 'Discovery', icon: this.GLOBAL.ICONS.DISCOVERY, info: { module: 'projects' } },
                    visit: { name: 'Visit', icon: 'directions_walk', info: { module: 'projects' } },
                    visitReport: { name: 'Visit Report', icon: 'transfer_within_a_station', info: { module: 'mandates' } },
                    offer: { name: 'Offer', icon: 'attach_money', info: { module: 'projects' } },
                    signAgree: { name: 'Sales Agreement', icon: 'drive_file_rename_outline', info: 'projects' },
                    signDeed: { name: 'Deed of Sale', icon: 'border_color', info: { module: 'projects' } },
                    signLease: { name: 'Lease Agreement', icon: 'border_color', info: { module: 'projects' } },
                    r1: { name: 'R1 - Discovery', icon: this.GLOBAL.ICONS.R1, info: { module: 'mandates' } },
                    r2: { name: 'R2 - Estimation', icon: this.GLOBAL.ICONS.R2, info: { module: 'mandates' } },
                    signMandate: { name: 'sign Mandate', icon: this.GLOBAL.ICONS.MANDATE, info: { module: 'mandates' } },
                    call: { name: 'call', icon: 'phone', info: { module: 'select' } },
                    email: { name: 'Email', icon: 'email', info: { module: 'select' } },
                    advertise: { name: 'Advertise', icon: this.GLOBAL.ICONS.MARKETING }
                }
            },

            offerStatus: {
                $key: 'offerStatus',
                title: 'Offer Status',
                name: 'Offer Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    accepted: { name: 'Accepted Offer' },
                    refused: { name: 'Refused Offer' },
                }
            },

            visitReportNext: {
                $key: 'visitReportNext',
                title: 'Visit Report Status',
                name: 'Visit Report Status',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    no: { name: 'Not Interested' },
                    think: { name: 'Not Sure' },
                    makeOffer: { name: 'Make an Offer' },
                }
            },

            mediums: {
                $key: 'mediums',
                title: 'Mediums',
                name: 'Medium',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    call: { name: 'Call', icon: 'phone' },
                    email: { name: 'Email', icon: 'email' },
                    whatsapp: { name: 'WhatsApp', icon: 'textsms' },
                    sms: { name: 'SMS', icon: this.GLOBAL.ICONS.NOTE },
                    walkin: { name: 'Office', icon: 'group' },
                }
            },

            meetLocations: {
                $key: 'meetLocations',
                title: 'Meet Locations',
                name: 'Meet Location',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    office: { name: 'Office' },
                    clientPlace: { name: "Client's Place" },
                    unitPlace: { name: "Property to Visit" },
                    cafe: { name: "Café/Restaurant" },
                    other: { name: "Other" },
                }
            },

            // errors: {
            //     $key: 'errors',
            //     title: 'Errors',
            //     name: 'Error',
            //     multiLang: false,
            //     desc: false,
            //     db: false,
            //     editable: false,
            //     values: {
            //         missingAddress: { name: 'Missing Address' },
            //         missingGPS: { name: 'Missing GPS' },
            //         missingECity: { name: 'Missing eCity' },
            //         missingOwner: { name: 'Missing Owner' },
            //         missingMaxBudget: { name: 'Missing Maximum Budget' },
            //         missingDateStart: { name: 'Missing Starting Date' },
            //         missingDateNeed: { name: 'Missing Entry Date' },
            //         missingPrice: { name: 'Missing Price' },
            //         missingDateAvailable: { name: 'Missing Available Date' },
            //         missingDuration: { name: 'Missing Mandate Duration' },
            //         missingFullName: { name: 'Missing Full Name' },
            //         missingFirstname: { name: 'Missing Firstname' },
            //         missingLastname: { name: 'Missing Lastname' },
            //         missingLang: { name: 'Missing Language' },
            //         missingNationalities: { name: 'Missing Contact Nationality' },
            //         missingUnitNb: { name: 'Missing Unit Number' },
            //         missingOrigin: { name: 'Missing Origin' },
            //         missingOriginMedium: { name: 'Missing Origin Medium' },
            //         noActiveAction: { name: 'No Active Action' },
            //     }
            // },

            permits: {
                $key: 'permits',
                title: 'Permits',
                name: 'Permit',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    no: { name: 'Not Resident' },
                    tourist: { name: 'Tourist' },
                    pds: { name: 'PDS' },
                    investor: { name: 'Investor' },
                    retire: { name: 'Retired' },
                    occupation: { name: 'Work' },
                    student: { name: 'Student' },
                }
            },

            soncas: {
                $key: 'soncas',
                title: 'SONCAS',
                name: 'SONCAS',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    sec: { name: 'Sécurité' },
                    org: { name: 'Orgueil' },
                    nou: { name: 'Nouveauté' },
                    con: { name: 'Confort' },
                    arg: { name: 'Argent' },
                    sym: { name: 'Sympathie' },
                }
            },

            waterHeaterTypes: {
                $key: 'waterHeaterTypes',
                title: 'Water Heater Types',
                name: 'Water Heater Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    solar: { name: 'Solar' },
                    gaz: { name: 'Gaz' },
                    elec: { name: 'Electrical' },
                    therm: { name: 'Thermodynamics' },
                    none: { name: 'None' },
                }
            },

            kitchenTypes: {
                $key: 'kitchenTypes',
                title: 'Water Heater Types',
                name: 'Water Heater Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    american: { name: 'American' },
                    split: { name: 'Separate Kitchen' },
                }
            },

            cmsTypes: {
                $key: 'cmsTypes',
                title: 'CMS Types',
                name: 'CMS Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    blog: { name: 'Blog' },
                    webpage: { name: 'Web Page' },
                }
            },

            cmsTemplates: {
                $key: 'cmsTemplates',
                title: 'CMS Templates',
                name: 'CMS Template',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    default: { name: 'Default' },
                }
            },

            cmsSectionTemplates: {
                $key: 'cmsSectionTemplates',
                title: 'CMS Section Templates',
                name: 'CMS Section Template',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    default: { name: 'Default' },
                }
            },

            cmsMediaTypes: {
                $key: 'cmsMediaTypes',
                title: 'CMS Section Media Types',
                name: 'CMS Section Media Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    img: { name: 'Image' },
                    vid: { name: 'Video' },
                    vr: { name: 'Virtual Tour' },
                }
            },

            cmsMediaPositions: {
                $key: 'cmsMediaPositions',
                title: 'CMS Section Media Positions',
                name: 'CMS Section Media Position',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    none: { name: 'None' },
                    r: { name: 'Right' },
                    l: { name: 'Left' },
                }
            },

            cmsTextTypes: {
                $key: 'cmsTextTypes',
                title: 'CMS Text Type',
                name: 'CMS Text Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    h3: { name: 'Title' },
                    p: { name: 'Paragraph' },
                    ul: { name: 'Bullet Points' },
                    ol: { name: 'Ordered List' },
                }
            },

            cmsTextColors: {
                $key: 'cmsTextColors',
                title: 'CMS Text Colors',
                name: 'Text Color',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    none: { name: 'Default' },
                    red: { name: 'Red' },
                    purple: { name: 'Purple' },
                    green: { name: 'Green' },
                    blue: { name: 'Blue' },
                    yellow: { name: 'Yellow' },
                }
            },

            // statTypes: {
            //     $key: 'statTypes',
            //     title: 'Stat Types',
            //     name: 'Type',
            //     multiLang: false,
            //     desc: false,
            //     db: false,
            //     editable: false,
            //     values: {
            //         projectOrigins: { name: "Project Origins", info: { param: true } },
            //     }
            // },


            paymentTypes: {
                $key: 'paymentTypes',
                title: 'Payment Types',
                name: 'Type',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    cash: { name: "Cash" },
                    cheque: { name: "Cheque" },
                    transfer: { name: "Transfer" },
                }
            },

            portals: {
                $key: 'portals',
                title: 'Portals',
                name: 'Portal',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    web: { name: 'Nestenn Websites', info: { default: true } },
                    lp: { name: 'L\'Express Property', info: { default: true } },
                    pc: { name: 'PropCloud', info: { default: true } },
                    je: { name: 'JamesEdition', info: { default: true } },
                    pm: { name: 'PropertyMap', info: { default: true } },
                    ta: { name: 'The Agency', info: { default: false } },
                    apimo: { name: "Polo Properties", info: { default: false } },
                    mer: { name: "Mer & Demeures", info: { default: false } },
                    ps: { name: "Properstar" },
                }
            },

            titles: {
                $key: 'titles',
                title: 'Titles',
                name: 'Title',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    MR: { name: 'Mr' },
                    MRS: { name: 'Mrs' },
                    MISS: { name: 'Ms' },
                }
            },

            cities: {
                $key: 'cities',
                title: 'Cities',
                name: 'City',
                multiLang: true,
                desc: false,
                db: true,
                editable: false,
                values: {}
            },

            countries: {
                $key: 'countries',
                title: 'Countries',
                name: 'Country',
                multiLang: false,
                desc: false,
                db: true,
                editable: false,
                values: {}
            },

            statReports: {
                $key: 'statReports',
                title: 'Stat Reports',
                name: 'Stat Report',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    actions: { name: 'Actions Report' },
                    projectOrigins: { name: 'Project Origins' },
                    mandateOrigins: { name: 'Mandate Origins' },
                    newMandates: { name: 'New Mandates' },
                    newMandatesVEFA: { name: 'New Mandates incl. Off-Plan' },
                    sales: { name: 'Committed Turnover - Sales/Rentals' },
                    salesType: { name: 'Committed Turnover - Projects/Mandates' },
                    nbDeals: { name: 'Nb Deals' },
                    nbDealProjects: { name: 'Nb Deal Projects' },
                    nbDealMandates: { name: 'Nb Deal Mandates' },
                    projects: { name: 'Stock Projects' },
                    mandates: { name: 'Stock Mandates' },
                    mandatesVEFA: { name: 'Stock Mandates incl. Off-Plan' },
                }
            },

            newMandates: {
                $key: 'newMandates',
                title: 'New Mandates',
                name: 'New Mandate',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    exc: { name: 'Exclusive' },
                    spl: { name: 'Simple' },
                }
            },

            newMandatesVEFA: {
                $key: 'newMandatesVEFA',
                title: 'New Mandates including VEFA',
                name: 'New Mandate including VEFA',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    vefa: { name: 'Off Plan' },
                    exc: { name: 'Exclusive' },
                    spl: { name: 'Simple' },
                }
            },

            sales: {
                $key: 'sales',
                title: 'sales',
                name: 'sale',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    project: { name: 'Project' },
                    mandate: { name: 'Mandate' },
                    vefa: { name: 'VEFA/Promoter' },
                }
            },

            vefaOld: {
                $key: 'vefaOld',
                title: 'vefaOld',
                name: 'vefaOld',
                multiLang: false,
                desc: false,
                db: false,
                editable: false,
                values: {
                    old: { name: 'Resale' },
                    vefa: { name: 'VEFA' },
                }
            },


        };


    get params(): { [key: string]: Param } {
        return this._params;
    }



    public getParam(param: string): Param | null {
        if (this._params[param]) {
            return this._params[param];
        }
        return null;
    }

    public getParamValues(paramType: string): { [key: string]: { name: string | { [lang: string]: string }, icon?: string } } {
        if (this._params[paramType] && this._params[paramType].values) {
            return this._params[paramType].values;
        }
        return {};
    }

    public getParamName(paramType: string, param: string, lang: string = 'en'): string {
        const paramTypeValues = this._params[paramType]?.values;
        if (param && paramTypeValues && paramTypeValues[param]?.name) {
            const paramName = paramTypeValues[param].name;
            if (typeof paramName === 'string') {
                return paramName;
            } else if (typeof paramName === 'object' && paramName[lang]) {
                return paramName[lang];
            }
        }
        return '';
    }

    public getParamIcon(paramType: string, param: string): string | undefined {
        if (param && this._params[paramType]
            && this._params[paramType].values
            && this._params[paramType].values[param]
            && this._params[paramType].values[param].icon) {
            return this._params[paramType].values[param].icon;
        }
        return '';
    }

    public regions: { sectors: sectors[] } =
        {
            sectors: [
                {
                    name: 'north',
                    title: 'North',
                    color: 'yellow',
                    paths: [{ lng: 57.48889713079399, lat: -20.13431666286353 }, { lng: 57.54386382717973, lat: -20.14425276550545 }, { lng: 57.6220716488852, lat: -20.18247896029222 }, { lng: 57.77216938954852, lat: -20.147390040113 }, { lng: 57.68348487832281, lat: -19.96715324142928 }, { lng: 57.53634040438363, lat: -19.96614151372663 }],
                    sectors: [
                        {
                            name: 'gb',
                            title: 'Grand Baie Area',
                            paths: [{ lng: 57.5576548297399, lat: -20.00833904704192 }, { lng: 57.58985128487833, lat: -20.03297993377166 }, { lng: 57.61689376507788, lat: -20.00375863203822 }, { lng: 57.62617137112695, lat: -20.00693235618115 }, { lng: 57.6402233181792, lat: -19.98141880914234 }, { lng: 57.61008923803668, lat: -19.97840681287712 }, { lng: 57.55018637462457, lat: -19.99706185016094 }, { lng: 57.55234053433635, lat: -20.01026425314542 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'cal',
                            title: 'Calodyne',
                            paths: [{ lng: 57.62616147563426, lat: -20.00694669086891 }, { lng: 57.65677064110746, lat: -20.01644382365205 }, { lng: 57.66904313405541, lat: -20.00242215897982 }, { lng: 57.64028542520824, lat: -19.98141758714216 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'gg',
                            title: 'Grand Gaube - St Antoine',
                            paths: [{ lng: 57.67127647853868, lat: -20.0274125749073 }, { lng: 57.6558797657282, lat: -20.04176095904549 }, { lng: 57.66520864801392, lat: -20.04974169661212 }, { lng: 57.68670614344721, lat: -20.03984549910581 }, { lng: 57.68483991542709, lat: -20.00768946493938 }, { lng: 57.66901898220601, lat: -20.0024038126578 }, { lng: 57.65678371563331, lat: -20.01644283298725 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'mc',
                            title: 'Mon Choisy - Trou aux Biches',
                            paths: [{ lng: 57.53977827561562, lat: -20.04123225978218 }, { lng: 57.54421724721828, lat: -20.04338285986821 }, { lng: 57.56394506094451, lat: -20.03665282066692 }, { lng: 57.57856249641161, lat: -20.0243321674768 }, { lng: 57.55770155171541, lat: -20.00833521171446 }, {
                                lng: 57.55231065447312, lat: -20.01014911766737
                            }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'pap',
                            title: 'Pointe aux Piments',
                            paths: [{ lng: 57.53981528169965, lat: -20.04125857356314 }, { lng: 57.52276352169387, lat: -20.04771719721519 }, { lng: 57.5133832255979, lat: -20.07190307694286 }, { lng: 57.52788123893469, lat: -20.07553683676835 }, { lng: 57.54422815725012, lat: -20.04337023610015 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'good',
                            title: 'Goodlands Area',
                            paths: [{ lng: 57.70275598208082, lat: -20.07632837799092 }, { lng: 57.68670637367868, lat: -20.03984351798162 }, { lng: 57.66520836655729, lat: -20.0497426677897 }, { lng: 57.6558920732349, lat: -20.04176265699784 }, { lng: 57.67126754698381, lat: -20.02740026171245 }, { lng: 57.65679034960852, lat: -20.01643878035096 }, { lng: 57.62621532184022, lat: -20.00694553592463 }, { lng: 57.62774571021288, lat: -20.02779678532106 }, { lng: 57.62225700967752, lat: -20.06160966882322 }, { lng: 57.64659164734103, lat: -20.09563470533665 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'pit',
                            title: 'd\'Aruty, Belle Vue, Piton',
                            paths: [{ lng: 57.61525555767089, lat: -20.12002491926199 }, { lng: 57.64659250927659, lat: -20.09566020291811 }, { lng: 57.62219305347129, lat: -20.06157141307261 }, { lng: 57.62772562078185, lat: -20.02781120097144 }, { lng: 57.62619879364886, lat: -20.00692967495869 }, { lng: 57.61682747089212, lat: -20.00370828283026 }, { lng: 57.58985474730503, lat: -20.03297523374861 }, { lng: 57.60216567270687, lat: -20.10577665644087 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'tp',
                            title: 'Triolet - Pamlemousses',
                            paths: [{ lng: 57.56392192151548, lat: -20.03666610985893 }, { lng: 57.54423279485385, lat: -20.04337873790829 }, { lng: 57.52788255410857, lat: -20.07554540496493 }, { lng: 57.51339812681289, lat: -20.07190289605693 }, { lng: 57.48907359475201, lat: -20.13430891368278 }, { lng: 57.54499983235365, lat: -20.14478877682823 }, { lng: 57.60284423615688, lat: -20.17306030458892 }, { lng: 57.61526414690166, lat: -20.1200201282942 }, { lng: 57.60215286797408, lat: -20.10576965106866 }, { lng: 57.58985171195748, lat: -20.03297718695624 }, { lng: 57.57856163828509, lat: -20.02434569710461 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                        {
                            name: 'rn',
                            title: 'Roches Noires Area',
                            paths: [{ lng: 57.70275923961358, lat: -20.07633093578681 }, { lng: 57.64658807136728, lat: -20.09562754910112 }, { lng: 57.61525667763148, lat: -20.12004893230745 }, { lng: 57.60282585575641, lat: -20.17299579170134 }, { lng: 57.62210572839454, lat: -20.18239010717119 }, { lng: 57.77200329092959, lat: -20.14737592584495 }, { lng: 57.74130924207704, lat: -20.0970783419615 }],
                            color: '#FFFF66',
                            sectors: null,
                        },
                    ]
                },
                {
                    name: 'est',
                    title: 'Est',
                    color: 'brown',
                    paths: [{ lng: 57.62278488861318, lat: -20.18253618528196 }, { lng: 57.65600354108533, lat: -20.21387197557443 }, { lng: 57.66948246796373, lat: -20.27035954793681 }, { lng: 57.65079898944153, lat: -20.33958687514193 }, { lng: 57.75906674884892, lat: -20.31324381604704 }, { lng: 57.78819114244683, lat: -20.32731244539614 }, { lng: 57.81765857460656, lat: -20.25870203671036 }, { lng: 57.77286441360893, lat: -20.14806686670013 }],
                    sectors: null,
                },
                {
                    name: 'pl',
                    title: 'Port-Louis',
                    color: 'grey',
                    paths: [{ lng: 57.47163675671288, lat: -20.17282433274356 }, { lng: 57.47360783700375, lat: -20.18532574318478 }, { lng: 57.47235943996704, lat: -20.18944743408953 }, { lng: 57.47444879592277, lat: -20.19325739570884 }, { lng: 57.47265365004237, lat: -20.20023401473554 }, { lng: 57.47489663070954, lat: -20.20951759657019 }, { lng: 57.47898498612656, lat: -20.21648124605895 }, { lng: 57.52274945494155, lat: -20.19783352767796 }, { lng: 57.55560151914865, lat: -20.19385041309882 }, { lng: 57.55161531769101, lat: -20.15247390022478 }, { lng: 57.54355961878573, lat: -20.14302047166921 }, { lng: 57.48886847335265, lat: -20.13413306166059 }],
                    sectors: null,
                },
                {
                    name: 'moka',
                    title: 'Moka - Quartier Militaire',
                    color: 'pink',
                    paths: [{ lng: 57.47911882009083, lat: -20.21651339055324 }, { lng: 57.49191613107934, lat: -20.22437401700746 }, { lng: 57.49975570081565, lat: -20.22862807151022 }, { lng: 57.50090669092651, lat: -20.23138666526602 }, { lng: 57.49822279626905, lat: -20.23973418426952 }, { lng: 57.49804186860413, lat: -20.24143644914552 }, { lng: 57.48824019580602, lat: -20.25733139510081 }, { lng: 57.4973269865125, lat: -20.27394032190616 }, { lng: 57.50101586243043, lat: -20.27701540426188 }, { lng: 57.50431594594035, lat: -20.27848315546306 }, { lng: 57.51335808570031, lat: -20.28309739644601 }, { lng: 57.51904533481886, lat: -20.28421212755399 }, { lng: 57.52478639319521, lat: -20.28694975295172 }, { lng: 57.52794844306872, lat: -20.29344486000078 }, { lng: 57.53354074337948, lat: -20.29576165798663 }, { lng: 57.53643793082686, lat: -20.30065280649148 }, { lng: 57.65055467154741, lat: -20.33988901790671 }, { lng: 57.66950917693194, lat: -20.27036942800824 }, { lng: 57.6560579326215, lat: -20.21385263834918 }, { lng: 57.62299951985938, lat: -20.1826789515153 }, { lng: 57.54718295778065, lat: -20.1461358428569 }, { lng: 57.55231376236509, lat: -20.15196538685009 }, { lng: 57.5549553339517, lat: -20.19323358977605 }, { lng: 57.52258201064792, lat: -20.19806009304856 }],
                    sectors: null,
                },
                {
                    name: '4b',
                    title: 'Quatre Bornes - Rose Hill',
                    color: 'orange',
                    paths: [{ lng: 57.50091203521549, lat: -20.23140456736313 }, { lng: 57.49977002495353, lat: -20.22861367393484 }, { lng: 57.47904664518841, lat: -20.21647057450914 }, { lng: 57.47490319130541, lat: -20.2094606857457 }, { lng: 57.47264594461417, lat: -20.20021729779029 }, { lng: 57.47444271961815, lat: -20.19330437595206 }, { lng: 57.47235820620913, lat: -20.18957570649828 }, { lng: 57.4735702696791, lat: -20.18530809984378 }, { lng: 57.47162015659362, lat: -20.17292788094212 }, { lng: 57.4752740119939, lat: -20.16452812928141 }, { lng: 57.46716856311404, lat: -20.1613014629873 }, { lng: 57.41373260925003, lat: -20.17771341088887 }, { lng: 57.43519043159055, lat: -20.19934118762808 }, { lng: 57.4481332464875, lat: -20.25644875845318 }, { lng: 57.43083351484662, lat: -20.29222985407514 }, { lng: 57.446757406696, lat: -20.30837658319545 }, { lng: 57.45984739278469, lat: -20.29012650643802 }, { lng: 57.46128352690526, lat: -20.29054742706861 }, { lng: 57.46594281670471, lat: -20.2896294053531 }, { lng: 57.47543577321385, lat: -20.28678620973288 }, { lng: 57.47682345840514, lat: -20.2869997487133 }, { lng: 57.47969013293989, lat: -20.28397291451325 }, { lng: 57.48399785099028, lat: -20.28222806883569 }, { lng: 57.49648988746134, lat: -20.27702937909917 }, { lng: 57.49747156969173, lat: -20.2739726053482 }, { lng: 57.48872713643964, lat: -20.25722237971606 }, { lng: 57.49801921046362, lat: -20.24135827952994 }],
                    sectors: null,
                },
                {
                    name: 'cf',
                    title: 'Curepipe - Floreal',
                    color: 'orange',
                    paths: [{ lng: 57.49649733652129, lat: -20.27702725314685 }, { lng: 57.47971050530085, lat: -20.28396990585572 }, { lng: 57.47681817029295, lat: -20.2870045505056 }, { lng: 57.47547772651364, lat: -20.28678515866113 }, { lng: 57.46133476778993, lat: -20.29055187524108 }, { lng: 57.45988878332406, lat: -20.2901435535829 }, { lng: 57.44678316054917, lat: -20.3083566266712 }, { lng: 57.45936512625936, lat: -20.32119869418663 }, { lng: 57.43076995095681, lat: -20.42905875818816 }, { lng: 57.49103831124691, lat: -20.44506080069375 }, { lng: 57.52418480038037, lat: -20.39574906710084 }, { lng: 57.58075243739697, lat: -20.35683682043661 }, { lng: 57.65051924844874, lat: -20.33982327500587 }, { lng: 57.53643501020193, lat: -20.30065455782925 }, { lng: 57.53353692223896, lat: -20.29576360315961 }, { lng: 57.52795225615122, lat: -20.2934402965552 }, { lng: 57.52478310731875, lat: -20.28694659816268 }, { lng: 57.51904588258304, lat: -20.2842156380134 }, { lng: 57.51337170965026, lat: -20.28310195268064 }, { lng: 57.50102139004635, lat: -20.27700893946949 }, { lng: 57.49745294377043, lat: -20.27405909227637 }, { lng: 57.49649733652129, lat: -20.27702725314685 }],
                    sectors: null,
                },
                {
                    name: 'west',
                    title: 'West',
                    color: 'green',
                    paths: [{ lng: 57.44801505088146, lat: -20.50954174193635 }, { lng: 57.4908530925646, lat: -20.44504711525023 }, { lng: 57.43067694591408, lat: -20.42905692817066 }, { lng: 57.4593134691562, lat: -20.32118470715681 }, { lng: 57.43085725979091, lat: -20.29224067609441 }, { lng: 57.44809015825889, lat: -20.25645989798135 }, { lng: 57.43521629065771, lat: -20.19934769589195 }, { lng: 57.41372731875079, lat: -20.17768180583539 }, { lng: 57.35237169342019, lat: -20.2859481248383 }, { lng: 57.29309562354778, lat: -20.47975102015216 }, { lng: 57.42082330785163, lat: -20.5231231200086 }, { lng: 57.44801505088146, lat: -20.50954174193635 }],
                    sectors: null,
                },
                {
                    name: 'south',
                    title: 'South',
                    color: 'blue',
                    paths: [{ lng: 57.58071894930039, lat: -20.35689276516616, }, { lng: 57.52419112638509, lat: -20.39577742811926, }, { lng: 57.44801611490574, lat: -20.50956322694007, }, { lng: 57.5144287553373, lat: -20.53169396912886, }, { lng: 57.70587936212866, lat: -20.47605488784731, }, { lng: 57.78813313870936, lat: -20.32733059541052, }, { lng: 57.75881155499759, lat: -20.31343178294101, }, { lng: 57.58071894930039, lat: -20.35689276516616 }],
                    sectors: null,
                },

            ]
        }
}

