<mat-toolbar class="residencesbg">

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Residences</span>
    </div>
    <div class="form">
        <button mat-icon-button *ngIf="db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.LIST}}</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.ADDRESS}}</mat-icon>
        </button>
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix *ngIf="searchForm.get('search').value" (click)="clearSearch()">close</mat-icon>
                <mat-icon matSuffix *ngIf="!searchForm.get('search').value">search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table class="main" [offset]="db.itemsPageConfig.scrollIndex" [ngClass]="{hide: db.itemsPageConfig.map}"
        (sortChange)="sortChange($event)" [item]="item" [items]="db.itemsFilteredOrdered" (clickFn)="goItem($event)"
        (overFn)="previewItem($event)" (scrollFn)="scrollItems($event)">
    </app-table>
    <app-gmap class="main" *ngIf="db.itemsPageConfig.map" [markers]="db.items" (clickFn)="goItem($event)"
        [params]="paramInit" (filter)="mapFilter($event)" (overFn)="previewItem($event)"></app-gmap>
    <div class="side">
        <mat-tab-group [(selectedIndex)]="db.itemsPageConfig.sideTab">

            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>

                <mat-list class="h s" *ngIf="db.loaded">
                    <mat-list-item *ngIf="db.summary.total" class="cursor" (click)="filterStatus('N')"
                        matTooltip="All Residences">
                        <mat-icon matListIcon class="residences">{{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
                        <h3 matLine> {{db.summary.total}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.active" class="cursor" (click)="filterStatus('active')"
                        matTooltip="Active Residences">
                        <mat-icon matListIcon class="active">{{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
                        <h3 matLine> {{db.summary.active}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.activeVefa" class="cursor" (click)="filterStatus('activeVefa')"
                        matTooltip="Active VEFA Residences">
                        <mat-icon matListIcon class="vefa">{{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
                        <h3 matLine> {{db.summary.activeVefa}} </h3>
                    </mat-list-item>
                </mat-list>

                <mat-list class="h s" *ngIf="db.loaded">
                    <ng-container *ngFor="let status of 'residenceStatus' | params">
                        <mat-list-item *ngIf="db.summary[status.id]" class="cursor" (click)="filterStatus(status.id)"
                            [matTooltip]="status.name">
                            <mat-icon matListIcon [ngClass]="status.color">{{status.icon}}{{status.color}}</mat-icon>
                            <h3 matLine> {{db.summary[status.id]}} </h3>
                        </mat-list-item>
                    </ng-container>
                    <mat-list-item *ngIf="db.summary.none" class="cursor" (click)="filterStatus('none')"
                        matTooltip="No Status">
                        <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                        <h3 matLine> {{db.summary.none}} </h3>
                    </mat-list-item>
                </mat-list>

                <form [formGroup]="sortLocalForm" novalidate>

                    <ng-container formGroupName="filters">
                        <mat-form-field appearance="fill">
                            <mat-label>Agency</mat-label>
                            <mat-select formControlName="agency">
                                <mat-option value="N">
                                    All Agencies
                                </mat-option>
                                <mat-option *ngFor="let param of 'agencies' | params" [value]="param.id">
                                    {{param.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill"
                            *ngIf="(sortLocalForm.get('filters.realtor').value && sortLocalForm.get('filters.realtor').value !== 'N') || (sortLocalForm.get('filters.agency').value && sortLocalForm.get('filters.agency').value !== 'N')">
                            <mat-label>Realtor</mat-label>
                            <mat-select formControlName="realtor">
                                <mat-option value="N">
                                    All Agents
                                </mat-option>
                                <mat-option
                                    *ngFor="let param of setting.realtorsAgency[sortLocalForm.get('filters.agency').value]"
                                    [value]="param.id">
                                    {{param.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container formGroupName="multiFilters">
                        <mat-form-field appearance="fill">
                            <mat-label>Regions</mat-label>
                            <mat-select formControlName="sector" [multiple]="true">
                                <!-- <mat-option value="">All Regions</mat-option> -->
                                <mat-option *ngFor="let region of setting.regions.sectors" [value]="region.name">
                                    {{region.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Schemes</mat-label>
                            <mat-select formControlName="scheme" [multiple]="true">
                                <mat-option *ngFor="let scheme of setting.settings.residenceSchemes.values | keyvalue"
                                    [value]="scheme.key">
                                    {{scheme.value.name?.en}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-form-field appearance="fill">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status" [multiple]="true">
                                <mat-option *ngFor="let status of setting.settings.residenceStatus.values | keyvalue"
                                    [value]="status.key">
                                    {{status.value.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> -->
                    </ng-container>
                </form>

            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-residence-card [values]="preview" [list]="true"></app-residence-card>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<button mat-fab class="bottom-right pfixed" [ngClass]="{p2: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>