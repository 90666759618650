<mat-toolbar class="mandatesbg">

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Mandates</span>
    </div>
    <div class="form">
        <button mat-icon-button *ngIf="db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.LIST}}</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.ADDRESS}}</mat-icon>
        </button>
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix *ngIf="searchForm.get('search').value" (click)="clearSearch()" >close</mat-icon>
                <mat-icon matSuffix *ngIf="!searchForm.get('search').value">search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <div>
        <button mat-icon-button *ngIf="!db.itemsPageConfig.referral" (click)="toggleReferral()">
            <mat-icon>{{GLOBAL.ICONS.REFERRAL}}</mat-icon>
        </button>
        <button mat-icon-button *ngIf="db.itemsPageConfig.referral" (click)="toggleReferral()">
            <mat-icon>{{GLOBAL.ICONS.MANDATE}}</mat-icon>
        </button>
        <profile></profile>
    </div>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table *ngIf="!db.itemsPageConfig.referral" class="main" [offset]="db.itemsPageConfig.scrollIndex"
        [tableKey]="db.isRole('realtor') ? 'main' : 'realtor'" [ngClass]="{hide: db.itemsPageConfig.map}" [item]="item"
        [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)" [sort]="db.localFilters.sort"
        (clickFn)="goItem($event)" (overFn)="previewItem($event)" (scrollFn)="scrollItems($event)">
    </app-table>
    <app-table *ngIf="db.itemsPageConfig.referral" class="main" [offset]="db.itemsPageConfig.scrollIndex"
        [tableKey]="db.isRole('realtor') ? 'referral' : 'referralRealtor'" [ngClass]="{hide: db.itemsPageConfig.map}"
        [item]="item" [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)" [sort]="db.localFilters.sort"
        (clickFn)="goItem($event)" (overFn)="previewItem($event)" (scrollFn)="scrollItems($event)">
    </app-table>
    <app-gmap class="main" *ngIf="db.itemsPageConfig.map" [markers]="db.itemsFiltered" (clickFn)="goItem($event)"
        (overFn)="previewItem($event)">
    </app-gmap>
    <div class="side">
        <mat-tab-group [(selectedIndex)]="db.itemsPageConfig.sideTab">
            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>
                <mat-list class="h s">
                    <mat-list-item class="cursor" (click)="filterStatus('N')" matTooltip="Active Mandates">
                        <mat-icon matListIcon class="mandates">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine
                            *ngIf="db.loaded && !(sortServerForm.value.multiFilters.status.includes('deal') || sortServerForm.value.multiFilters.status.includes('failed'))">
                            {{db.items.length}}</h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.vefa" class="cursor" (click)="filterStatus('active', 'vefa')" matTooltip="VEFA Mandates">
                        <mat-icon matListIcon class="vefa">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> {{db.summary.vefa}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" (click)="filterStatus('failed')" matTooltip="Failed Mandates">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.FAILED}}</mat-icon>
                        <h3 matLine *ngIf="db.loaded && sortServerForm.value.multiFilters.status.includes('failed')">
                            {{db.items.length}}</h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="h s" *ngIf="db.loaded">
                    <mat-list-item class="cursor" *ngIf="db.summary.new" (click)="filterStatus('new')" matTooltip="New Mandates">
                        <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.NEW}}</mat-icon>
                        <h3 matLine> {{db.summary.new}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="!db.summary.new">
                        <h3 matLine>&nbsp;</h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" *ngIf="db.summary.pending" (click)="filterStatus('pending')" matTooltip="Pending Mandates">
                        <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.PENDING}}</mat-icon>
                        <h3 matLine> {{db.summary.pending}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="!db.summary.pending">
                        <h3 matLine>&nbsp;</h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.pause" class="cursor" (click)="filterStatus('pause')" matTooltip="Paused Mandates">
                        <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.PAUSE}}</mat-icon>
                        <h3 matLine> {{db.summary.pause}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="!db.summary.pause">
                        <h3 matLine>&nbsp;</h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="h s" *ngIf="db.loaded">
                    <mat-list-item *ngIf="db.summary.hot" class="cursor" (click)="filterStatus('active', 'hot')" matTooltip="Hot Mandates">
                        <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> {{db.summary.hot}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.warm" class="cursor" (click)="filterStatus('active', 'warm')" matTooltip="Warm Mandates">
                        <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> {{db.summary.warm}} </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="db.summary.cold" class="cursor" (click)="filterStatus('active', 'cold')" matTooltip="Cold Mandates">
                        <mat-icon matListIcon class="cold">{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> {{db.summary.cold}} </h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="h s" *ngIf="db.loaded">
                    <mat-list-item class="cursor" *ngIf="db.summary.offer" (click)="filterStatus('offer')" matTooltip="Pending Offers">
                        <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.OFFER}}</mat-icon>
                        <h3 matLine> {{db.summary.offer}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" *ngIf="db.summary.signLease" (click)="filterStatus('signLease')" matTooltip="Pending Lease Agreements">
                        <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNLEASE}}</mat-icon>
                        <h3 matLine> {{db.summary.signLease}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" *ngIf="db.summary.signAgree" (click)="filterStatus('signAgree')" matTooltip="Pending Sales Agreements">
                        <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNAGREE}}</mat-icon>
                        <h3 matLine> {{db.summary.signAgree}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" *ngIf="db.summary.signDeed" (click)="filterStatus('signDeed')" matTooltip="Pending Final Signatures">
                        <mat-icon matListIcon class="warm">{{GLOBAL.ICONS.SIGNDEED}}</mat-icon>
                        <h3 matLine> {{db.summary.signDeed}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" *ngIf="db.summary.payment" (click)="filterStatus('payment')" matTooltip="Pending Payments">
                        <mat-icon matListIcon class="hot">{{GLOBAL.ICONS.PAYMENT}}</mat-icon>
                        <h3 matLine> {{db.summary.payment}} </h3>
                    </mat-list-item>
                    <mat-list-item class="cursor" (click)="filterStatus('deal')" matTooltip="Dealed Mandates">
                        <mat-icon matListIcon class="active">{{GLOBAL.ICONS.DEAL}}</mat-icon>
                        <h3 matLine *ngIf="db.loaded && sortServerForm.value.multiFilters.status.includes('deal')">
                            {{db.items.length}}</h3>
                    </mat-list-item>
                </mat-list>
                <form [formGroup]="sortServerForm" novalidate>



                    <mat-form-field appearance="fill"
                        *ngIf="!db.itemsPageConfig.referral && !select.active && setting.getParamsSelect('userAgencies') && setting.getParamsSelect('userAgencies').length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant', 'marketing'])">
                        <mat-label>Agency</mat-label>
                        <mat-select formControlName="agency">
                            <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill" *ngIf="!db.itemsPageConfig.referral && select.active">
                        <mat-label>Agency</mat-label>
                        <mat-select formControlName="agency">
                            <mat-option *ngFor="let param of 'agencies' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill"
                        *ngIf="!db.itemsPageConfig.referral && !select.active && db.auth.isOneOfRoles(['admin', 'director', 'assistant', 'marketing'])">
                        <mat-label>Realtor</mat-label>
                        <mat-select formControlName="realtor">
                            <mat-option value="">
                                All Agents
                            </mat-option>
                            <mat-option
                                *ngFor="let param of setting.realtorsAgency[sortServerForm.controls['agency'].value]"
                                [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field formGroupName="filters" appearance="fill"
                        *ngIf="db.itemsPageConfig.referral && db.auth.profile.values.agencies.length > 1 && db.auth.isOneOfRoles(['admin', 'director', 'assistant'])">
                        <mat-label>Referral Agency</mat-label>
                        <mat-select formControlName="referralAgency">
                            <mat-option *ngFor="let param of 'userAgencies' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field formGroupName="filters" appearance="fill"
                        *ngIf="db.itemsPageConfig.referral && !select.active && db.auth.isOneOfRoles(['admin', 'director', 'assistant', 'marketing'])">
                        <mat-label>Referral Agent</mat-label>
                        <mat-select formControlName="referral">
                            <mat-option value="">
                                All Agents
                            </mat-option>
                            <mat-option
                                *ngFor="let param of setting.realtorsAgency[sortServerForm.get('filters.referralAgency').value]"
                                [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </form>

                <form [formGroup]="sortLocalForm" novalidate>
                    <!-- <mat-form-field formGroupName="filters" appearance="fill" *ngIf="!select.active">
                        <mat-label>VEFA</mat-label>
                        <mat-select formControlName="condition">
                            <mat-option value="N">No selection</mat-option>
                            <mat-option *ngFor="let param of 'unitConditionsVEFA' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field formGroupName="filters" appearance="fill" *ngIf="!select.active">
                        <mat-label>Mandate Type</mat-label>
                        <mat-select formControlName="type">
                            <mat-option value="N">No selection</mat-option>
                            <mat-option *ngFor="let param of 'mandateTypes' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <ng-container *ngIf="sortServerForm.value.arrayFilters.mandateTypes == 'sale'">
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Min Budget</mat-label>
                            <input matInput formControlName="saleMinPrice" type="number">
                        </mat-form-field>
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Max Budget</mat-label>
                            <input matInput formControlName="saleMaxPrice" type="number">
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="sortServerForm.value.arrayFilters.mandateTypes == 'rental'">
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Min Budget</mat-label>
                            <input matInput formControlName="rentalMinPrice" type="number">
                        </mat-form-field>
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Max Budget</mat-label>
                            <input matInput formControlName="rentalMaxPrice" type="number">
                        </mat-form-field>
                    </ng-container> -->
                </form>
            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-unit-card [values]="preview" [mandate]="true"></app-unit-card>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<!-- <button mat-fab class="bottom-right pfixed" [ngClass]="{p2: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button> -->

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>