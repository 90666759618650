<mat-toolbar [ngClass]="item.values.vefa ? 'vefasbg' : 'unitsbg'">
    <div>
        <button mat-icon-button *ngIf="!pageConfig.edit" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <a mat-icon-button (click)="displayItem()" *ngIf="pageConfig.edit">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <div class="title">
            <mat-icon [ngClass]="{active:mandateActive}">{{setting.getParamIcon('unitTypes', item?.values?.type)}}
            </mat-icon>
            <ng-container *ngIf="!new">
                P{{item.values.ref}}:
                {{item.values.vefa ? 'VEFA - ' : ''}}{{item.values.typeDisp}}
                - {{item.values.address?.eCity | param : 'cities'}}
                {{item.values.vefa ? ' - ' + item.values.vefaName : ''}}
                {{item.values.pm ? ' - Property Management' : ''}}
            </ng-container>
            <ng-container *ngIf="new">New Property</ng-container>
        </div>
    </div>
    <div>
        <ng-container *ngIf="item && item.values">
            {{item.values.score ? (item.values.score + '%') : 'Missing Parameters' }}
            <button mat-icon-button [matMenuTriggerFor]="alerts">
                <mat-icon
                    [ngClass]="{'hot': item.values.score < 50, 'warm': item.values.score >= 50 && item.values.score < 80, 'active': item.values.score >= 80}">{{item.values.error
                    ? GLOBAL.ICONS.ALERT : GLOBAL.ICONS.MARKETING}}</mat-icon>

            </button>
            <mat-menu #alerts="matMenu">
                <button mat-menu-item *ngFor="let alert of item.values.alerts">
                    <mat-icon color="hot" *ngIf="alert.type === 'error'">{{GLOBAL.ICONS.ERROR}}</mat-icon>
                    <mat-icon color="warm" *ngIf="alert.type === 'alert'">{{GLOBAL.ICONS.ALERT}}</mat-icon>
                    <mat-icon color="accent" *ngIf="alert.type === 'marketing'">{{GLOBAL.ICONS.MARKETING}}</mat-icon>
                    <span>{{alert.code | param:'alerts'}}</span>
                </button>
            </mat-menu>
        </ng-container>
        <profile></profile>
        <!-- <mat-icon style="margin-left:10px;" *ngIf="item.values.marketingCheck" color="primary">verified</mat-icon> -->
        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="duplicate()">
                <mat-icon>{{GLOBAL.ICONS.DUPLICATE}}</mat-icon>
                <span>Duplicate</span>
            </button>
            <button mat-menu-item (click)="logs()">
                <mat-icon>{{GLOBAL.ICONS.LOGS}}</mat-icon>
                <span>Logs</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="mdc-layout-grid unitPage mainPage" *ngIf="!pageConfig.edit">
    <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell">
            <mat-card class="full">
                <mat-list *ngIf="pageConfig.loaded">
                    <mat-list-item *ngIf="item.values.synaps?.residenceUnitsObj" class="cursor"
                        (click)="goItem('residences', item.values.synaps?.residenceUnitsObj)">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.RESIDENCE}}</mat-icon>
                        <h3 matLine> {{item.values | address}} </h3>
                        <button *ngIf="item.values.address?.gps?.longitude" mat-icon-button
                            (click)="copyGoogleMapURL($event)">
                            <mat-icon>{{GLOBAL.ICONS.COPY}}</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="!item.values.synaps?.residenceUnitsObj">
                        <mat-icon matListIcon>
                            {{GLOBAL.ICONS.ADDRESS}}</mat-icon>
                        <h3 matLine> {{item.values | address}} </h3>
                        <button *ngIf="item.values.address?.gps?.longitude" mat-icon-button
                            (click)="copyGoogleMapURL($event)">
                            <mat-icon>{{GLOBAL.ICONS.COPY}}</mat-icon>
                        </button>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.vefa">
                        <mat-icon matListIcon>
                            {{setting.getParamIcon('unitTypes', item?.values?.type)}}</mat-icon>
                        <h3 matLine> {{item.values.vefaAv !== null ? item.values.vefaAv : '?'}}/{{item.values.vefaNb ?
                            item.values.vefaNb : '?'}} </h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="h">
                    <mat-list-item>
                        <mat-icon matListIcon>{{GLOBAL.ICONS.BED}}</mat-icon>
                        <h3 matLine> {{item.values.bedrooms}} </h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>{{GLOBAL.ICONS.BATH}}</mat-icon>
                        <h3 matLine> {{item.values.bathrooms}} </h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>{{GLOBAL.ICONS.WC}}</mat-icon>
                        <h3 matLine> {{item.values.toilets}} </h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="h">
                    <mat-list-item>
                        <mat-icon matListIcon>{{GLOBAL.ICONS.SURFACE}}</mat-icon>
                        <h3 matLine> {{item.values.livingSurface | number}} / {{item.values.builtSurface | number}} m²
                        </h3>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-icon matListIcon>{{GLOBAL.ICONS.LAND}}</mat-icon>
                        <h3 matLine> {{item.values.landSurface | number}} {{item.values.landUnit | param:'landUnits'}}
                        </h3>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.gardenSurface">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.GARDEN}}</mat-icon>
                        <h3 matLine> {{item.values.gardenSurface | number}} {{item.values.gardenUnit |
                            param:'landUnits'}} </h3>
                    </mat-list-item>
                </mat-list>
                <mat-list class="icons">
                    <mat-list-item *ngIf="item.values.pm">
                        <mat-icon color="accent" matListIcon>{{GLOBAL.ICONS.PM}}</mat-icon>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.includeAC">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.AC}}</mat-icon>
                    </mat-list-item>
                    <mat-list-item
                        *ngIf="item.values.params && (item.values.params.privatePool || item.values.params.commonPool)">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.POOL}}</mat-icon>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.carSpaces && item.values.carSpaces.garage">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.GARAGE}}</mat-icon>
                        <h3 matLine> {{item.values.carSpaces.garage}} </h3>
                    </mat-list-item>

                    <mat-list-item *ngIf="item.values.video?.url">
                        <mat-icon [ngClass]="{active:item?.values.video?.ad}" (click)="newTab(item.values.video.url)"
                            class="cursor" matListIcon>{{GLOBAL.ICONS.VIDEO}}
                        </mat-icon>
                    </mat-list-item>
                    <mat-list-item *ngIf="item.values.vr?.url">
                        <mat-icon [ngClass]="{active:item?.values.vr?.ad}" (click)="newTab(item.values.vr.url)"
                            class="cursor" matListIcon>{{GLOBAL.ICONS.VR}}</mat-icon>
                    </mat-list-item>
                </mat-list>
                <!-- <mat-list>
                    <mat-list-item class="cursor" *ngFor="let mandate of item.values.synaps.unitMandatesArray"
                        (click)="goItem('mandates', mandate)">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.MANDATE}}</mat-icon>
                        <h3 matLine> NES{{mandate.ref}} {{mandate.dateStart.toDate() | date:'dd MMM yyyy'}} {{mandate.price | number}} {{mandate.cy | param:'cies'}}</h3>
                    </mat-list-item>
                </mat-list> -->
            </mat-card>

        </div>
        <div class="mdc-layout-grid__cell--span-4">
            <app-gps *ngIf="pageConfig.loaded" style="min-height: 250px; height: 100%;" [values]="item.values">
            </app-gps>
        </div>
        <div class="mdc-layout-grid__cell--span-4">
            <slideshow *ngIf="pageConfig.loaded" synap="filesUnits" [item]="item" ></slideshow>
        </div>
        <div class="mdc-layout-grid__cell--span-8">
            <table mat-table [dataSource]="item.values.synaps?.unitMandatesArray" class="mat-elevation-z8">

                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon matListIcon [ngClass]="element | mandateIconColor">
                            {{setting.getParamIcon('mandateStatus', element.status)}}
                        </mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="ref">
                    <th mat-header-cell *matHeaderCellDef> Ref </th>
                    <td mat-cell *matCellDef="let element"> NES{{element.ref}} </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.dateAvailable && element.dateAvailable.seconds ? (element.dateAvailable.toDate() |
                        date:'dd MMM yyyy') : ''}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{setting.getParamValueName('mandateTypes', element.type)}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Price</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.priceDisplay}}
                        <ng-container *ngIf="element.priceNegDisplay"><br />
                            <i>{{element.priceNegDisplay}}</i>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="realtor">
                    <th mat-header-cell *matHeaderCellDef>Agent</th>
                    <td mat-cell *matCellDef="let element">
                        <img mat-card-avatar *ngIf="element.realtor" class="realtor" [src]="element.realtor | realtor"
                            [matTooltip]="element.realtor | realtorName" />
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="['icon','ref', 'date', 'type', 'price', 'realtor']">
                </tr>
                <tr mat-row
                    *matRowDef="let row; let element; columns: ['icon','ref', 'date', 'type', 'price', 'realtor'];"
                    (click)="goItem('mandates', element)" class="cursor">
                </tr>
            </table>
        </div>
        <div class="mdc-layout-grid__cell--span-4">
            <mat-card>
                <mat-list *ngIf="item.values.synaps?.ownersArray && !item.values.vefa">
                    <mat-list-item class="cursor" *ngFor="let contact of item.values.synaps.ownersArray"
                        (click)="goItem('contacts', contact)">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
                        <h3 matLine> {{contact.fullname}} </h3>
                    </mat-list-item>
                </mat-list>
                <mat-list *ngIf="promoter && item.values.vefa">
                    <mat-list-item class="cursor" (click)="goItem('companies', promoter)">
                        <mat-icon matListIcon>{{GLOBAL.ICONS.CONTACT}}</mat-icon>
                        <h3 matLine> {{promoter.displayname}} </h3>
                    </mat-list-item>
                </mat-list>
            </mat-card>
        </div>

        <div class="mdc-layout-grid__cell--span-4">
            <form-builder [item]="item" [values]="item.values" section="display" [edit]="false"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-2">
            <form-builder [item]="item" [values]="item.values" section="params" [edit]="false"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-2">
            <form-builder [item]="item" [values]="item.values" section="equipment" [edit]="false"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-2">
            <form-builder [item]="item" [values]="item.values" section="appliances" [edit]="false"></form-builder>
            <form-builder [item]="item" [values]="item.values" section="security" [edit]="false"></form-builder>
        </div>
        <div class="mdc-layout-grid__cell--span-2">
            <form-builder [item]="item" [values]="item.values" section="view" [edit]="false"></form-builder>
            <form-builder [item]="item" [values]="item.values" section="area" [edit]="false"></form-builder>
        </div>
    </div>
</div>

<!------------------------------ EDIT MODE  ----------------------------------------->

<div class="unitPage mainPage" *ngIf="pageConfig.edit">
    <mat-tab-group class="main" [(selectedIndex)]="pageConfig.editTab" (selectedTabChange)="onTabChanged($event)">



        <!-- RESIDENCE -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.RESIDENCE}}</mat-icon>&nbsp;
                {{item.values.synaps?.residenceUnits && item.values.synaps.residenceUnits != 'loading' &&
                item.values.synaps.residenceUnitsObj ? item.values.synaps.residenceUnitsObj.name : 'No Residence' }}
            </ng-template>

            <app-residence-tab
                *ngIf="item.values.synaps?.residenceUnits && item.values.synaps.residenceUnits != 'loading' && item.values.synaps.residenceUnitsObj"
                [key]="item.values.synaps.residenceUnitsObj.$key"></app-residence-tab>

            <mat-spinner [strokeWidth]="5" class="fixed" *ngIf="item.values.synaps?.residenceUnits == 'loading'">
            </mat-spinner>

            <button mat-fab class="bottom-right pfixed p2" color="basic"
                (click)="select.init(item, db,'residenceUnits')">
                <mat-icon matListIcon>sync_alt</mat-icon>
            </button>
        </mat-tab>

        <!-- PROPERTY DETAILS -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DETAILS}}</mat-icon>&nbsp;
                Details
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell">
                        <form-builder [item]="item" [values]="item.values" section="main" [edit]="true"
                            (touch)="refresh()">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell" *ngIf="!new">
                        <form-builder [item]="item" [values]="item.values" section="numbers" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell" *ngIf="!new">
                        <form-builder [item]="item" [values]="item.values" section="surface" [edit]="true">
                        </form-builder>
                    </div>
                </div>
            </div>

            <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>

        <!-- ADDRESS / GPS -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.ADDRESS}}</mat-icon>&nbsp;
                Address
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div style="height: 500px;" class="mdc-layout-grid__cell--span-8">
                        <app-gps [edit]="true" [control]="true" [values]="item.values" (modify)="saveAddress($event)"
                            [fullscreenMode]="false">
                        </app-gps>
                    </div>
                    <div class="mdc-layout-grid__cell">
                        <form-builder [item]="item" [values]="item.values" section="address" [edit]="true">
                        </form-builder>
                    </div>
                </div>
            </div>

            <!-- EDIT MODE -->
            <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <!-- <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed p2" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button> -->
        </mat-tab>


        <!-- PROPERTY DETAILS -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>&nbsp;
                Parameters
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="params" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="equipment" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="appliances" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="security" [edit]="true">
                        </form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="view" [edit]="true"></form-builder>
                    </div>
                    <div class="mdc-layout-grid__cell--span-2">
                        <form-builder [item]="item" [values]="item.values" section="area" [edit]="true"></form-builder>
                    </div>
                </div>
            </div>

            <!-- EDIT MODE -->
            <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>

        <!-- DESCRIPTIONS -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.DESC}}</mat-icon>&nbsp;
                Description
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-12">
                        <form-builder [item]="item" [values]="item.values" section="desc" [edit]="true"></form-builder>
                    </div>
                </div>
            </div>

            <!-- EDIT MODE -->
            <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
            <button mat-fab class="bottom-right pfixed p3" [disabled]="item.saving" color="primary"
                (click)="translate()">
                <mat-icon matListIcon>g_translate</mat-icon>
            </button>
            <!-- CHAT GPT -->
            <button *ngIf="!new" color="accent" mat-fab class="bottom-right pfixed p4"
                [disabled]="chatGPTDisable || (item.values?.desc?.en?.length > 0 && item.values?.title?.en?.length > 0)"
                (click)="generateText()">
                <mat-icon matListIcon>generating_tokens</mat-icon>
            </button>
        </mat-tab>

        <!-- PHOTOS -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PHOTOS}}</mat-icon>&nbsp;
                {{getFilesUnitsLength(item.values?.synaps?.filesUnits)}} Photos
            </ng-template>
            <upload-images [item]="item" synap="filesUnits">
            </upload-images>
        </mat-tab>

        <!-- EXTERNAL MEDIAS -->
        <mat-tab *ngIf="!new">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.MEDIAS}}</mat-icon>&nbsp;
                Ext. Medias
            </ng-template>

            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell--span-12">
                        <form-builder [item]="item" [values]="item.values" section="externalMedias" [edit]="true">
                        </form-builder>
                    </div>
                </div>
            </div>

            <!-- EDIT MODE -->
            <button *ngIf="!new && pageConfig.edit" mat-fab class="bottom-right pfixed"
                [disabled]="!item.valid || !item.modified || item.saving" (click)="saveItem()">
                <mat-icon matListIcon>check</mat-icon>
            </button>
            <button mat-fab class="bottom-right pfixed p2" [disabled]="!item.modified || item.saving" color="basic"
                (click)="cancelEdit()">
                <mat-icon matListIcon>close</mat-icon>
            </button>
        </mat-tab>

        <!-- OWNERS -->
        <mat-tab *ngIf="!new && !item.values.vefa">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.CONTACTS}}</mat-icon>&nbsp;
                Owners
            </ng-template>


            <div class="mdc-layout-grid">
                <div class="mdc-layout-grid__inner">
                    <div class="mdc-layout-grid__cell" *ngFor="let contact of item.values.synaps?.ownersArray">
                        <app-contact-tab [key]="contact.$key" model="units"
                            [modelKey]="item.values.$key"></app-contact-tab>
                    </div>
                </div>
            </div>

            <button mat-fab class="bottom-right pfixed p2" color="basic" (click)="select.init(item, db, 'owners')">
                <mat-icon matListIcon>sync_alt</mat-icon>
            </button>

        </mat-tab>
    </mat-tab-group>
</div>

<mat-spinner [strokeWidth]="5" class="fixed" *ngIf="!(pageConfig.loaded || new)"></mat-spinner>

<!-- NEW -->
<button *ngIf="new" mat-fab class="bottom-right pfixed" [disabled]="!item.valid || !item.modified || item.saving"
    (click)="saveNewItem()">
    <mat-icon matListIcon>check</mat-icon>
</button>
<!-- EDIT -->
<button *ngIf="!new && !pageConfig.edit" mat-fab class="bottom-right pfixed" (click)="editItem()">
    <mat-icon matListIcon>edit</mat-icon>
</button>
<!-- NEW MANDATE -->
<button *ngIf="!new && !pageConfig.edit && !(item.values.vefa && item.values.synaps?.unitMandatesArray?.length)"
    [disabled]="item.values.error && isRole('realtor')" [matTooltipDisabled]="!item.values.error"
    matTooltip="Check Errors before Adding a Mandate" color="primary" mat-fab class="bottom-right pfixed p2"
    (click)="addMandate()">
    <mat-icon matListIcon>{{GLOBAL.ICONS.MANDATEADD}}</mat-icon>
</button>
<!-- MARKETING APPROVE -->
<!-- <button *ngIf="!new && !item.values.marketingCheck && isOneOfRoles(['admin', 'marketing'])" color="accent" mat-fab
    class="bottom-right pfixed p5" (click)="marketingApprove()">
    <mat-icon matListIcon>verified</mat-icon>
</button> -->
<!-- DELETE -->
<button *ngIf="!new && !pageConfig.edit && isRole('admin')" mat-fab class="bottom-left pfixed" color="basic"
    (click)="db.deleteItem(item)">
    <mat-icon matListIcon>delete</mat-icon>
</button>