<mat-toolbar class="unitsbg">
    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Properties</span>
        <!-- <mat-spinner [strokeWidth]="5" *ngIf="db.serverLoading"></mat-spinner>
        <mat-spinner [strokeWidth]="5" color="accent" *ngIf="db.filterLoading"></mat-spinner> -->
    </div>
    <div class="form">
        <button mat-icon-button *ngIf="db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.LIST}}</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!db.itemsPageConfig.map" (click)="toggleMap()">
            <mat-icon>{{GLOBAL.ICONS.ADDRESS}}</mat-icon>
        </button>
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix *ngIf="searchForm.get('search').value" (click)="clearSearch()" >close</mat-icon>
                <mat-icon matSuffix *ngIf="!searchForm.get('search').value">search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <profile></profile>
</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex split">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table class="main" [offset]="db.itemsPageConfig.scrollIndex" [ngClass]="{hide: db.itemsPageConfig.map}"
        [item]="item" [items]="db.itemsFilteredOrdered" (sortChange)="sortChange($event)" (clickFn)="goItem($event)"
        (overFn)="previewItem($event)" (scrollFn)="scrollItems($event)"></app-table>
    <app-gmap class="main" *ngIf="db.itemsPageConfig.map" [markers]="db.items" (clickFn)="goItem($event)"
        [params]="paramInit" (filter)="mapFilter($event)" (overFn)="previewItem($event)">
    </app-gmap>
    <div class="side">
        <mat-tab-group [(selectedIndex)]="db.itemsPageConfig.sideTab">

            <!-- FILTERS -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.PARAM}}</mat-icon>
                </ng-template>

                <form [formGroup]="sortServerForm" novalidate>
                    <ng-container formGroupName="multiFilters">
                        <mat-form-field appearance="fill">
                            <mat-label>Regions</mat-label>
                            <mat-select formControlName="sector" [multiple]="true">
                                <!-- <mat-option value="">All Regions</mat-option> -->
                                <mat-option *ngFor="let region of setting.regions.sectors" [value]="region.name">
                                    {{region.title}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                    <ng-container formGroupName="arrayFilters">
                        <mat-form-field appearance="fill">
                            <mat-label>Mandate Type</mat-label>
                            <mat-select formControlName="mandateTypes">
                                <mat-option value="">No selection</mat-option>
                                <mat-option *ngFor="let param of 'mandateTypes' | params" [value]="param.id">
                                    {{param.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>

                </form>

                <form [formGroup]="sortLocalForm" novalidate>
                    <mat-form-field formGroupName="multiFilters" appearance="fill">
                        <mat-label>Unit Types</mat-label>
                        <mat-select formControlName="type" [multiple]="true">
                            <mat-option *ngFor="let param of 'unitTypes' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field formGroupName="multiFilters" appearance="fill">
                        <mat-label>Condition</mat-label>
                        <mat-select formControlName="condition" [multiple]="true">
                            <mat-option *ngFor="let param of 'unitConditionsVEFA' | params" [value]="param.id">
                                {{param.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field formGroupName="equalSup" appearance="fill">
                        <mat-label>Bedrooms</mat-label>
                        <mat-select formControlName="bedrooms">
                            <mat-option value="">No selection</mat-option>
                            <mat-option *ngFor="let nb of filterBeds" [value]="nb">
                                {{nb}}-bed+
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="sortServerForm.value.arrayFilters.mandateTypes == 'sale'">
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Min Budget</mat-label>
                            <input matInput formControlName="saleMinPrice" type="number">
                        </mat-form-field>
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Max Budget</mat-label>
                            <input matInput formControlName="saleMaxPrice" type="number">
                        </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="sortServerForm.value.arrayFilters.mandateTypes == 'rental'">
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Min Budget</mat-label>
                            <input matInput formControlName="rentalMinPrice" type="number">
                        </mat-form-field>
                        <mat-form-field formGroupName="budget" appearance="fill">
                            <mat-label>Max Budget</mat-label>
                            <input matInput formControlName="rentalMaxPrice" type="number">
                        </mat-form-field>
                    </ng-container>
                </form>
            </mat-tab>

            <!-- PREVIEW -->
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">{{GLOBAL.ICONS.VIEW}}</mat-icon>
                </ng-template>
                <app-unit-card [values]="preview"></app-unit-card>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>

<button mat-fab class="bottom-right pfixed" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>