<mat-toolbar class="companiesbg">

    <div>
        <a mat-icon-button (click)="clickBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <span>{{db.itemsFiltered.length}} Companies</span>
    </div>
    <div class="form">
        <form [formGroup]="searchForm" novalidate>
            <mat-form-field appearance="fill">
                <mat-label>Search</mat-label>
                <input matInput formControlName="search">
                <mat-icon matSuffix *ngIf="searchForm.get('search').value" (click)="clearSearch()" >close</mat-icon>
                <mat-icon matSuffix *ngIf="!searchForm.get('search').value">search</mat-icon>
            </mat-form-field>
        </form>
    </div>
    <profile></profile>

</mat-toolbar>
<div class="toolbartop"></div>

<div class="main-container-flex">
    <mat-spinner *ngIf="!db.loaded" [strokeWidth]="5"></mat-spinner>
    <app-table [item]="item" [items]="db.itemsFilteredOrdered" [offset]="db.itemsPageConfig.scrollIndex"
        (sortChange)="sortChange($event)" (clickFn)="goItem($event)" (scrollFn)="scrollItems($event)">
    </app-table>
</div>

<button mat-fab class="bottom-right pfixed" [ngClass]="{p2: select.active}" color="basic" (click)="addItem()">
    <mat-icon matListIcon>add</mat-icon>
</button>

<button *ngIf="select.active" mat-fab class="bottom-right pfixed" (click)="select.done()">
    <mat-icon matListIcon>done</mat-icon>
</button>